import { Chip } from '@nextui-org/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORES_ETIQUETAS } from '../../config/coloresEtiquetas';

const obtenerColorPorTipo = (tipo) => {
  return COLORES_ETIQUETAS[tipo] || 'bg-black';
};

const TipoEtiqueta = ({ tipo }) => {
  const { t } = useTranslation();

  const colorClase = obtenerColorPorTipo(tipo);

  return (
  <Chip 
    radius="full"
    variant='solid' 
    className={`m-1 ${colorClase} text-white`}
  >
    {t(`types.${tipo}`)}
  </Chip>);
};

export default TipoEtiqueta;
