import React from 'react';
import { Chip } from "@nextui-org/react";

const AccordionTitle = ({ title, selectedOptions, tema, expanded }) => {
    return (
        <div className="flex items-center justify-between">
            <div className="flex flex-col">
                <span className="font-semibold text-lg leading-none">{title}</span>
                {!expanded && selectedOptions?.length > 0 && (
                    <div 
                        className="flex flex-wrap gap-1 mt-1 overflow-hidden"
                        style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            maxHeight: '48px', // Altura de dos líneas aproximadamente
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {selectedOptions.map((subtemaId) => {
                            const subtema = tema.subtemas.find(st => st.id === subtemaId);
                            return subtema ? (
                                <Chip key={subtema.id} color="primary" variant="solid" size="sm" className="ml-1">
                                    {subtema.title}
                                </Chip>
                            ) : null;
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AccordionTitle;
