import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasTitle } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BarList from "../BarList/BarList";
import './CarruselListBares.styles.css';

export default function CarruselListBares({topBars,showCarruselList,titulo, onClose, handleBarSelect}) {
    const { t } = useTranslation();
	const [carrusel, setCarrusel] = useState(false);


	useEffect(() => {
		setCarrusel(showCarruselList);
	}, [showCarruselList]);

	const handleClose = () => {
		setCarrusel(false);
		if (onClose) onClose();
	}

	return (
		<Offcanvas show={carrusel} onHide={handleClose} placement='bottom' backdrop={false}>
			<OffcanvasHeader className='d-flex justify-content-between align-items-center py-0' closeButton>
				<OffcanvasTitle>{t(`listadoBares.titulo.${titulo}`)}</OffcanvasTitle>
			</OffcanvasHeader>
			<OffcanvasBody className='bg-secondary-light p-0'>
				{ topBars && topBars.length > 0 ? 
					<BarList barsData={topBars} onSelectBar={handleBarSelect} /> 
					: 
					<p className="no-bars-message">{t(`listadoBares.sinBares.${titulo}`)}</p>}
			</OffcanvasBody>
		</Offcanvas>
	);
}
