import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nBackend from "i18next-http-backend";

const loadPath = process.env.REACT_APP_I18N_LOAD_PATH

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        lng: 'es',
        fallbackLng: 'es',
        interpolation:{
            escapeValue: false
        },
        backend: {
            loadPath: process.env.REACT_APP_FRONTEND_URL+'/i18n/{{lng}}.json',
        }
    });

export default i18n;