import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const PerfilPictureUpload = ({ onImageSelect }) => {
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		onImageSelect(file);
	};

	return (
		<div>
			{/* Botón personalizado para activar el diálogo de selección de archivos */}
			<label htmlFor="file-upload" className="custom-file-upload">
				<FontAwesomeIcon icon={faEdit} />
			</label>
			{/* Input de tipo archivo oculto */}
			<input
				id="file-upload"
				type="file"
				accept="image/*"
				style={{ display: 'none' }}
				onChange={handleFileChange}
			/>
		</div>
	);
};

export default PerfilPictureUpload;
