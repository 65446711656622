import React from 'react';
import './MenuLateral.styles.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ImageComentario from '../../assets/icons/comentario.png';
import ImageConfiguracion from '../../assets/icons/configuraciones.png';
import ImageLike from '../../assets/icons/like.png';
import ImageCerrarSesion from '../../assets/icons/cerrar-sesion.png';
import ImageGuardados from '../../assets/icons/save.png';
import ImagePerfil from '../../assets/icons/usuario.png';
import ImageSinPerfil from '../../assets/icons/foto-perfil.png';
import ImageGyp from '../../assets/icons/pulgar-arriba-negro.png';
import { Listbox, ListboxItem, User } from '@nextui-org/react';
import { OffcanvasBody, OffcanvasHeader, OffcanvasTitle } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ListboxWrapper } from './ListboxWrapper';
import api from '../../services/ApiService';
import { useUser } from '../../contexts/UserContext';

const MenuLateral = ({
    handleProfileClick,
    handleMenuOptionClick,
    handleGyp,
    handleConfiguracion,
    handleLogout,
    handleCloseMenuLateral
}) => {
    const { user, authenticated, obtenerImagenPerfil } = useUser();
    const { t } = useTranslation();

    const handleMenuSelect = (option) => {
        switch (option) {
            case 'guardados':
                handleMenuOptionClick(api.users.getWishBars, false);
                break;
            case 'meGusta':
                handleMenuOptionClick(api.users.getFavoriteBars, true);
                break;
            case 'opiniones':
                handleComentarios();
                break;
            case 'gyp':
                handleGyp();
                break;
            case 'configuracion':
                handleConfiguracion();
                break;
            case 'cerrarSesion':
                handleLogout();
                break;
            default:
                break;
        }
    };
    
    const handleComentarios = async () => {
        if (user){
            alert("Mostrar Comentarios");
        }else{
            openModalSinSesion();
        }
    }

    const iconClasses = "text-xl text-default-500 pointer-events-none flex-shrink-0  w-10";
    
    return (
        <>
        <Offcanvas 
            show={true} 
            onHide={handleCloseMenuLateral}   
            className="px-4 py-2" 
        >
            <OffcanvasHeader className='d-flex justify-content-between align-items-center' closeButton>
                { user ? 
                    <User
                        name={user?.profile?.given_name}
                        description={user?.email}
                        avatarProps={{
                            isBordered: true,
                            color:"primary" ,
                            src:obtenerImagenPerfil(user),
                            alt:"Imagen de Perfil", 
                            size: "lg",
                            onClick: handleProfileClick,
                        }}
                    />    
                    :
                    <>
                    <User
                        src={ImageSinPerfil}
                        avatarProps={{
                            isBordered: true,
                            alt:"Imagen de Perfil", 
                            size: "lg"
                        }}
                    />
                    <OffcanvasTitle>{t('menu')}</OffcanvasTitle>
                    </>
                }
                
            </OffcanvasHeader>
            <hr className="my-4" />
            <OffcanvasBody  className='p-0'>
                <ListboxWrapper>
                    <Listbox 
                        aria-label="User Menu"
                        variant="faded">
                        <ListboxItem
                            key="perfil"
                            onClick= {handleProfileClick}
                            showDivider
                            startContent={<img src={ImagePerfil} alt="perfil" className={iconClasses} />}>
                        {t('menuLateral.infoPersonal')}
                        </ListboxItem>
                        <ListboxItem
                            key="gyp"
                            onClick={() => handleMenuSelect('gyp')}
                            startContent={<img src={ImageGyp} alt="gustosYPreferencias" className={iconClasses} />}>
                        {t('menuLateral.gyp')}
                        </ListboxItem>
                        {/* <ListboxItem
                            key="guardados"
                            onClick={() => handleMenuSelect('guardados')}
                            startContent={<img src={ImageGuardados} alt="guardados" className={iconClasses} />}>
                        {t('menuLateral.guardados')}
                        </ListboxItem> */}
                        <ListboxItem
                            key="meGusta"
                            onClick={() => handleMenuSelect('meGusta')}
                            startContent={<img src={ImageLike} alt="meGusta" className={iconClasses} />}>
                        {t('menuLateral.meGusta')}
                        </ListboxItem>
                        {/* <ListboxItem
                            key="opiniones"
                            onClick={() => handleMenuSelect('opiniones')}
                            startContent={<img src={ImageComentario} alt="opiniones" className={iconClasses} />}>
                        {t('menuLateral.opiniones')}
                        </ListboxItem> */}
                        <ListboxItem
                            key="configuracion"
                            onClick={() => handleMenuSelect('configuracion')}
                            showDivider
                            startContent={<img src={ImageConfiguracion} alt="configuracion" className={iconClasses} />}>
                        {t('menuLateral.configuracion')}
                        </ListboxItem>
                        { authenticated && (
                        <ListboxItem
                            key="cerrarSesion"
                            className="text-danger"
                            onClick={() => handleMenuSelect('cerrarSesion')}
                            color="danger"
                            startContent={<img src={ImageCerrarSesion} alt="cerrarSesion" className={iconClasses} />}>
                        {t('menuLateral.cerrarSesion')}
                        </ListboxItem> )}
                    </Listbox>
                </ListboxWrapper>
            </OffcanvasBody>
        </Offcanvas>
        </>
    );
};

export default MenuLateral;