import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PerfilPictureUpload from './PerfilPictureUpload';
import ImageProfile from './ImageProfile';
import { useUser } from '../../contexts/UserContext';

const UserInformation = ({ editing, onImageSelect, selectedImage }) => {
    
    const { t } = useTranslation();
    const { user } = useUser();
    
    return (
        <div className="user-information  border-b border-gray-400 pb-2">
            <div className="flex">
                <div className="relative p-1">
                    <div>
                        <ImageProfile alt='User information picture' className="w-20 h-20 text-large" />
                    </div>
                    {editing && (
                        <div className="edit-icon">
                            <PerfilPictureUpload onImageSelect={onImageSelect} />
                        </div>
                    )}
                    {selectedImage && (
                        <p className="selected-image-name">
                            {selectedImage?.name}
                        </p>
                    )}
                </div>
                <div className="pl-4">
                    <p><span className="field-label">{t('userProfile.username')}:</span> {user?.username}</p>
                    <p><span className="field-label">{t('userProfile.email')}: </span> {user?.email} <FontAwesomeIcon icon={faEnvelope} className="email-icon" /> </p>
                    <p><span className="field-label">{t('userProfile.userLevel')}: </span> {user?.userLevel}</p>
                </div>
            </div>
        </div>
    );
};

export default UserInformation;
