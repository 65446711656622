class UserRatingsDto {
    constructor(user, bar, comment, starsRating, tags) {
        this.user = user?._id;
        this.bar = bar._id;
        this.comment = comment;
        this.starsRating = starsRating;
        this.tags = tags;
    }
}

export default UserRatingsDto;
