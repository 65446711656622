import React, { useState } from 'react';
import MenuLateral from './MenuLateral';
import CarruselListBares from '../CarruselListBares/CarruselListBares';
import ConfigModal from '../ConfigModal/ConfigModal';
import SinSesion from "../SinSesionPopUp/SinSesion";
import Login from '../ButtonLogin/Login';
import Register from '../ButtonLogin/Register';
import Logout from '../ButtonLogin/Logout';
import { useUser } from '../../contexts/UserContext';
import { LIST_BARES } from '../../constants';
import GustosYPreferencias from '../GustosYPreferencias/GustosYPreferencias';

function MenuLateralContainer({ onClose, onProfileClick, handleBarSelect, openModalSinSesion}) {
    const { user, authenticated } = useUser();
    const [showMenuLateral, setShowMenuLateral] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showLogout, setShowLogout] = useState(false);
    const [showCarruselList, setShowCarruselList] = useState(false);
    const [currentBars, setCurrentBars] = useState(null);
    const [meGustaOGuardado, setMeGustaOGuardado] = useState(false);
    const [showConfigModal, setShowConfigModal] = useState(false);
    const [showGypModal, setShowGypModal] = useState(false);

    const handleMenuOptionClick = async (apiFunction, meGusta) => {
        if (authenticated) {
            const bars = await apiFunction(user?._id);
            setMeGustaOGuardado(meGusta);
            setCurrentBars(bars);
            setShowCarruselList(true);
            setShowMenuLateral(false);
        } else {
            openModalSinSesion();
        }
    };
  
    const handleConfiguracion = () => {
        if (authenticated){
            setShowMenuLateral(false);
            setShowConfigModal(true);
        }else{
            openModalSinSesion();
        }
    };
    
    const handleGyp = () => {
        if (authenticated){
            setShowMenuLateral(false);
            setShowGypModal(true);
        }else{
            openModalSinSesion();
        }
    };

    const handleProfileClick = () => {
        if (!authenticated) {
            setShowLogin(true);
            setShowMenuLateral(false);
        } else {
            onProfileClick();
        }
    };

    const handleChangeAuth = () => {
        setShowLogin(!showLogin);
        setShowRegister(!showRegister);
        setShowMenuLateral(false);
    };

    const handleLogout = () => {
        setShowMenuLateral(false);
        setShowLogout(true);
    }
  
    return (
        <>
            {showMenuLateral && (
                <MenuLateral
                    handleProfileClick={handleProfileClick}
                    handleMenuOptionClick={handleMenuOptionClick}
                    handleConfiguracion={handleConfiguracion}
                    handleGyp={handleGyp}
                    handleLogout={handleLogout}
                    handleCloseMenuLateral={onClose}
                />
            )}

            {showLogin && <Login handleChangeForm={handleChangeAuth} handleClose={onClose} />}
            {showRegister && <Register handleChangeForm={handleChangeAuth} handleClose={onClose} />}
            {showLogout && <Logout setShowLogout={setShowLogout} />}
            {showCarruselList && (
                <CarruselListBares
                    topBars={currentBars}
                    showCarruselList={showCarruselList}
                    titulo={meGustaOGuardado ? LIST_BARES.meGusta : LIST_BARES.guardados}
                    onClose={onClose}
                    handleBarSelect={handleBarSelect}
                />
            )}
            {showConfigModal && (
                <ConfigModal onClose={onClose} />
            )}
            {showGypModal && (
                <GustosYPreferencias onClose={onClose} />
            )}

        </>
    )
}

export default MenuLateralContainer