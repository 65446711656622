import axios from 'axios';
const API_URL = process.env.REACT_APP_BACKEND_URL;
const bars = "/bars";

const apiBars = API_URL + bars;


const BarImageService = {
    async getBarImages(barId) {

        console.log(`BarImageService - Obtener imagenes de ${barId}`);
        const cacheKey = `barImages_${barId}`;
        const cachedImages = JSON.parse(localStorage.getItem(cacheKey)) || [];
        
        console.log(`BarImageService - Se buscan en cache ${cachedImages}`);
        if (cachedImages.length > 0) {
            console.log(`BarImageService - Se devuelven imagenes de cache`);
            return cachedImages;
        }

        try {
            console.log(`BarImageService - Solicitud al backend para obtener imagenes de ${barId}`);
            const response = await axios.get(`${apiBars}/${barId}/images`);
            const imagenesBar = response.data.images || [];

            localStorage.setItem(cacheKey, JSON.stringify(imagenesBar));
            
            console.log(`BarImageService - Se devuelven ${imagenesBar.length} imagenes.`);
            return imagenesBar;
        } catch (error) {
            console.error('Error fetching bar images:', error.message);
            return [];
        }
    },
    async getBarImagenPrincipal(barId) {

        console.info(`BarImageService - getBarImagenPrincipal - Obtener imagen principal de ${barId}`);
        const cacheKey = `barImages_${barId}`;
        const cachedImages = JSON.parse(localStorage.getItem(cacheKey)) || [];
        
        console.info(`BarImageService - Se buscan en cache ${cachedImages}`);
        if (cachedImages.length > 0) {
            console.info(`BarImageService - Se devuelve la primera imagen de cache`);
            return cachedImages[0];
        }

        try {
            console.info(`BarImageService - Solicitud al backend para obtener la imagen principal de ${barId}`);
            const response = await axios.get(`${apiBars}/${barId}/images/principal`);
            const imagenBar = response.data.imagenPrincipal;

            console.info(`BarImageService - Se devuelve ${imagenBar?.length} imagenes.`);
            return imagenBar;
        } catch (error) {
            console.error('Error fetching bar images:', error.message);
            return [];
        }
    }
};

export default BarImageService;
