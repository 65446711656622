import React from 'react';
import { DatePicker, Input, Select, SelectItem } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import DateUtils from '../../utils/DateUtils';
import { COUNTRIES } from '../../config/countries';
import { GENDER } from '../../config/gender';

const UserTabPerfil = ({ editing, editedUser, handleChange }) => {
    const { t } = useTranslation();
    
    return (
        <div className="user-profile-form">
            <div className="flex flex-col gap-2">
                <p>
                    {editing ? (
                        <Input
                            type="text"
                            name="profile.given_name"
                            label={<span style={{fontWeight: 'bold'}}>{t('userProfile.nombre')}</span>}
                            labelPlacement="inside"
                            variant='bordered'
                            value={editedUser?.profile?.given_name}
                            onValueChange={(e) => handleChange("profile.given_name", e)}
                        />
                    ) : (
                        <>
                            <span className='field-label'>{t('userProfile.nombre')}: </span>
                            {editedUser?.profile?.given_name}
                        </>
                    )}
                </p>
                <p>
                {editing ? (
                    <Input
                        type="text"
                        name="profile.family_name"
                        label={<span style={{fontWeight: 'bold'}}>{t('userProfile.apellidos')}</span>}
                        labelPlacement="inside"
                        variant='bordered'
                        value={editedUser?.profile?.family_name}
                        onValueChange={(e) => handleChange("profile.family_name", e)}
                    />
                ) : (
                    <>
                        <span className='field-label'>{t('userProfile.apellidos')}: </span> 
                        {editedUser?.profile?.family_name}
                    </>
                )}
                </p>
                
                <p>
                    {editing ? (
                        <Select
                            items={COUNTRIES}
                            label={<span style={{fontWeight: 'bold'}}>{t('userProfile.pais')}</span>}
                            labelPlacement="inside"
                            variant="bordered"
                            onChange={(e) => handleChange("profile.locale", e.target.value)}
                            defaultSelectedKeys={[editedUser?.profile?.locale]}
                        >
                            {(pais) => <SelectItem key={pais.code}>{t(`paises.${pais.code}`)}</SelectItem>}
                        </Select>
                    ) : (
                        <>
                            <span className='field-label'>{t('userProfile.pais')}: </span>
                            {editedUser?.profile?.locale ? t(`paises.${editedUser.profile.locale}`) : ''}
                        </>
                    )}
                </p>
                <p>
                    {editing ? (
                        <Select
                            items={GENDER}
                            label={<span style={{fontWeight: 'bold'}}>{t('userProfile.genero')}</span>}
                            labelPlacement="inside"
                            variant="bordered"
                            onChange={(e) => handleChange("profile.gender", e.target.value)}
                            defaultSelectedKeys={[editedUser?.profile?.gender]}
                        >
                            {(genero) => <SelectItem key={genero.value}>{t(`generos.${genero.value}`)}</SelectItem>}
                        </Select>
                    ) : (
                        <>
                            <span className='field-label'>{t('userProfile.genero')}: </span>
                            {editedUser?.profile?.gender ? t(`generos.${editedUser.profile.gender}`) : ''}
                        </>
                    )}
                </p>
                <p>
                    {editing ? (
                        <div className="w-full max-w-xl flex flex-row gap-4">
                            <DatePicker
                                label={<span style={{fontWeight: 'bold'}}>{t('userProfile.fechaNacimiento')}</span>}
                                variant="bordered"
                                showMonthAndYearPickers
                                onChange={(date) => handleChange("profile.dateOfBirth", date)}
                            />
                        </div>
                    ) : (
                        <>
                        <span className='field-label'>{t('userProfile.fechaNacimiento')}: </span>
                        {DateUtils.formatDateDDMMYYYY(editedUser?.profile?.dateOfBirth)}
                        </>
                    )}
                </p>
            </div>
        </div>
    );
};

export default UserTabPerfil;
