import { useState, useEffect } from 'react';
import apiTags from '../../services/ApiTagService';
import { PREFERENCES } from '../../constants';
import { useTranslation } from 'react-i18next';


const usePreferenceOptions = () => {
  const { t } = useTranslation();

  const [optionsPreferenceMusic, setOptionsPreferenceMusic] = useState([]);
  const [optionsDrinkPreferences, setOptionsDrinkPreferences] = useState([]);
  const [optionsAmbiancePreference, setOptionsAmbiancePreference] = useState([]);
  const [optionsFavoriteBarTypes, setOptionsFavoriteBarTypes] = useState([]);
  const [optionsFavoriteActivities, setOptionsFavoriteActivities] = useState([]);

  useEffect(() => {
    const handleOptions = async () => {
      setOptionsPreferenceMusic(await getOptionsByStyle(PREFERENCES.musica));
      setOptionsDrinkPreferences(await getOptionsByStyle(PREFERENCES.bebida));
      setOptionsAmbiancePreference(await getOptionsByStyle(PREFERENCES.ambiente));
      setOptionsFavoriteBarTypes(await getOptionsByStyle(PREFERENCES.tipoLocal));
      setOptionsFavoriteActivities(await getOptionsByStyle(PREFERENCES.actividad));
    };

    handleOptions();
  }, []);

  const getOptionsByStyle = async (style) => {
    try {
      const optionsBackend = await apiTags.getTagsByStyle(style);
      const options = optionsBackend.map(tag => ({
        value: tag.name,
        label: t(`tags.${style}.${tag.name}`)
      }));
      return options;
    } catch (error) {
      console.error("Error al recuperar tags por style:", error);
      return [];
    }
  };

  return {
    optionsPreferenceMusic,
    optionsDrinkPreferences,
    optionsAmbiancePreference,
    optionsFavoriteBarTypes,
    optionsFavoriteActivities,
  };
};

export default usePreferenceOptions;
