import React from 'react';
import { Divider, Image } from '@nextui-org/react';
import IconTextSection from './IconTextSection';
import OpinionesSection from './OpinionesSection';
import imgRating from '../../assets/icons/estrella.png'; 
import imgComentario from '../../assets/icons/comentario-alt1.png'; 
import MapUtils from '../../utils/MapUtils';
import IconTextComentarioSection from './IconTextComentarioSection';
import StarRating from '../StarRating/StarRating';
import { useTranslation } from 'react-i18next';

const MisOpinionesSection = ({ ratingsUser, ratingsUserBar, abrirNotificaciones }) => {
    
    const { t } = useTranslation();
    
    return (
        <>  
            { ratingsUserBar.starsRating ? (
                <div className="flex gap-3">
                    <Image src={imgRating} height={28} width={28} radius="sm" className="category-chip" />
                    <div className="bar-detalles-types">
                        <h6 className="category-chip">{t(`notificaciones.subtemas.starsRating.titulo`)}</h6>
                        <StarRating stars={ratingsUserBar.starsRating} />
                        {' (' + MapUtils.formato1Decimal(ratingsUserBar.starsRating) + ') '}
                    </div>
                </div>
                )
                : 
                <IconTextSection
                    icon={imgRating} 
                    miOpinion={true}
                    abrirNotificaciones={abrirNotificaciones}
                    translationKey="starsRating"
                />
            }
            
            <Divider className='bg-primary'/>
            <OpinionesSection
                iteracionesFiltradas={ratingsUser}
                miOpinion={true}
                abrirNotificaciones={abrirNotificaciones}
            />
            <Divider className='bg-primary'/>
            <IconTextComentarioSection
                    icon={imgComentario}
                    content={ratingsUserBar.comment}
                    abrirNotificaciones={abrirNotificaciones}
                />
        </>
    );
};

export default MisOpinionesSection;
