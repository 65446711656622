import { Alert } from 'bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { useLoading } from './useLoading';

const useUserLocation = () => {
    const initialLocation = { lat: 40.4281882, lng: -3.7217989 };
    const [userLocation, setUserLocation] = useState(initialLocation);
    const [loadingUserLocation, setLoadingUserLocation] = useState(true);
    const [error, setError] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [reubicar, setReubicar] = useState(false);
    const { stopLoading } = useLoading();

    // Function to handle location updates
    const handlePosition = useCallback((position) => {
        const { latitude, longitude } = position.coords;
        const newLocation = { lat: latitude, lng: longitude };
        
        setUserLocation(newLocation); 
        setLoadingUserLocation(false);
    }, []);

    // Function to handle errors
    const handleError = useCallback((error) => {
        setError(`Error: ${error.message}`);
        setLoadingUserLocation(false);
        console.error('Geolocation error:', error);
        setModalVisible(true);
    }, []);

    // Función para manejar la selección de ciudad
    const handleSelectCity = (city) => {
        const { latitude, longitude } = city.coords
            ? city.coords
            : city;
        const newLocation = { lat: latitude, lng: longitude };
        setUserLocation(newLocation); 
        console.log(userLocation);
        setModalVisible(false);
        stopLoading();
    };

    const handleReubicar = () => {
        console.log("handleReubicar");
        setReubicar(true);
        setModalVisible(false);
        window.location.reload();
    };

    useEffect(() => {
        let watchId = null;
        setLoadingUserLocation(true);
        setReubicar(false);
        
        if (navigator.geolocation) { 
            console.log(navigator);
            
            watchId = navigator.geolocation.watchPosition(
                handlePosition,
                handleError,
                {
                    enableHighAccuracy: true, // Alta precisión
                    timeout: 10000, // Timeout ajustado para actualizaciones más frecuentes
                    maximumAge: 0 // Desactiva el almacenamiento en caché
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
            setLoadingUserLocation(false);
            setModalVisible(true);
        }

        return () => {
            if (watchId !== null) {
                navigator.geolocation.clearWatch(watchId);
            }
            setLoadingUserLocation(false);
        };
    }, [handlePosition, handleError, reubicar]);

    return { userLocation, setUserLocation, error, loadingUserLocation, initialLocation, modalVisible, setModalVisible, 
        handleSelectCity, handleReubicar };
};

export default useUserLocation;
