export const filterIteraciones = (iteraciones) => {

    console.info("filterIteraciones - Inicio:", iteraciones);

    const result = {};

    Object.keys(iteraciones).forEach((tipo) => {
        const subtipos = iteraciones[tipo];

        const filteredSubtipos = Object.entries(subtipos).filter(
            ([subtipo, value]) => value >= 1
        );

        // Ordenar los subtipos de mayor a menor valor
        filteredSubtipos.sort((a, b) => b[1] - a[1]);

        if (filteredSubtipos.length > 0) {
            result[tipo] = Object.fromEntries(filteredSubtipos);
        }
    });

    console.info("filterIteraciones - Fin:", result);

    return Object.keys(result).length > 0 ? result : [];
};

export const filterRatingsUser = (ratings, idBar) => {

    console.info("filterRatingsUser - Inicio");

    let result = {};
    
    if (Array.isArray(ratings)) {
        ratings?.forEach((rating) => {
            if (rating.bar === idBar) {
                
                rating.tags?.forEach((tag) => {
                    if (!result[tag.style]) {
                        result[tag.style] = {};
                    }
                    result[tag.style][tag.name] = 1;
                });
            
            }
        });
    }

    console.info("filterRatingsUser - Fin. Tags:", result);

    return Object.keys(result).length > 0 ? result : [];
};

export const filterRatingsUserBar = (ratings, idBar) => {

    console.info("filterRatingsUserBar - Inicio");

    let result = {};

    if (Array.isArray(ratings)) {
        ratings.forEach((rating) => {
            if (rating.bar === idBar) {
                result = rating;
            }
        });
    }

    console.info("filterRatingsUserBar - Fin:", result);

    return Object.keys(result).length > 0 ? result : [];
};

export const isOpenNow = (hoursArray) => {
    // Parsear el string de horarios

    console.log(hoursArray);
    if (!Array.isArray(hoursArray) || hoursArray.length === 0) {
        console.error("El array de horarios no es válido.");
        return null;
    }

    // Convertir el array de horarios en un objeto para fácil acceso
    const days = hoursArray?.map(dayString => {
        const [day, hours] = dayString.split(': ');

        if (!day || !hours) {
            console.error(`Formato de horario incorrecto: ${dayString}`);
            return null;
        }

        // Manejar el caso "Cerrado"
        if (hours.toLowerCase().includes('cerrado')) {
            return {
                day: day.trim().toLowerCase(),
                open: null,
                close: null
            };
        }

        // Manejar el caso "Abierto 24 horas"
        if (hours.toLowerCase().includes('abierto 24 horas')) {
            return {
                day: day.trim().toLowerCase(),
                open: '00:00',
                close: '23:59'
            };
        }

        const [open, close] = hours.split('–');

        if (!open || !close) {
            console.error(`Formato de horario incorrecto: ${dayString}`);
            return null;
        }

        return {
            day: day.trim().toLowerCase(),
            open: open.trim(),
            close: close.trim()
        };
    }).filter(Boolean);

    // Verificar si se obtuvo algún horario válido
    if (days.length === 0) {
        console.error("No se encontraron horarios válidos.");
        return null;
    }

    // Obtener el día y hora actual
    const now = new Date();
    const dayNames = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
    const currentDay = dayNames[now.getDay()];
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    // Buscar los horarios para el día actual
    const currentHours = days?.find(day => day.day === currentDay);
    
    if (!currentHours) {
        return null; // Día no encontrado en los horarios
    }

    // Verificar si está cerrado
    if (currentHours.open == null) {
        return false ;  // Está cerrado todo el día
    }


    // Convertir hora de apertura y cierre a minutos desde medianoche
    const convertToMinutes = (time) => {
        const [hour, minute] = time.split(':').map(Number);
        return hour * 60 + minute;
    };

    const openMinutes = convertToMinutes(currentHours.open);
    const closeMinutes = convertToMinutes(currentHours.close);
    const nowMinutes = currentHour * 60 + currentMinute;

    // Verificar si la hora actual está dentro del rango de apertura
    return (openMinutes <= nowMinutes && nowMinutes <= closeMinutes) || (openMinutes > closeMinutes && (nowMinutes >= openMinutes || nowMinutes <= closeMinutes));
}

