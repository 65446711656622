import axios from "axios";
import AuthUtils from "../utils/AuthUtils";

const url = "/userRatings";
const urlBase =  process.env.REACT_APP_BACKEND_URL+url;

const apiUserRatings = {

    guardarRatingsYComentarios: async (userRatingsDto) => {
        try {
            const response = await axios.post(`${urlBase}/guardarRatingsYComentarios`,userRatingsDto);
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getRatingInBarByUser: async (userBarDto) => {
        try {
            const response = await axios.post(`${urlBase}/getRatingInBarByUser`,userBarDto);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getRatingsByUser: async (userId) => {
        try {
            if (AuthUtils.isAuth()) {
                const response = await axios.get(`${urlBase}/getRatingsByUser/${userId}`);
                return response.data;
            }
            return null;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    
}

export default apiUserRatings;