import { Navbar, NavbarBrand, NavbarContent, NavbarItem } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import MenuButton from '../SearchCard/MenuButton';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import IconButton from './IconButton';
import LoginButton from './LoginButton';
import './NavBar.styles.css';
import { useUser } from '../../contexts/UserContext';
import UserProfile from '../UserProfile/UserProfile';
import MenuLateralContainer from '../MenuLateral/MenuLateralContainer';
import SinSesion from '../SinSesionPopUp/SinSesion';
import LogoCompleto from '../../assets/icons/logoCompleto.png';
import LogoCompletoGrande from '../../assets/icons/logoCompletoGrande.png';
import LogoLetras from '../../assets/icons/logoLetrasSolo.png';
import LogoIcono from '../../assets/icons/logoIconoSolo.png';

function NavBar({handleBarSelect, toggleSearchCard, toggleFilterCard}) {

    const { authenticated } = useUser();
    const [showMenuLateral, setShowMenuLateral] = useState(false);
    const [showUserProfile, setShowUserProfile] = useState(false);
    const [isOpenSinSesion, setIsOpenSinSesion] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [logo, setLogo] = useState(LogoCompleto);

    useEffect(() => {
        // Función para detectar si la pantalla es móvil
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
        // Escucha el evento de cambio de tamaño de la ventana
        window.addEventListener('resize', handleResize);
        // Llama a la función una vez al principio para establecer el estado inicial
        handleResize();
        // Elimina el evento de escucha al desmontar el componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Inicializa la visibilidad de las tarjetas según el tamaño de la pantalla
        if (isMobile) {
            setLogo(LogoCompleto);
        } else {
            setLogo(LogoCompletoGrande);
        }
    }, [isMobile]);

    const handleMenuButtonClick = () => {
		setShowMenuLateral(!showMenuLateral);
	};

    const handleProfileClick = () => {
		setShowUserProfile(true);
		setShowMenuLateral(false);
	};

    const handleCloseProfile = () => {
		setShowUserProfile(false);
	}

    const openModalSinSesion = () => {
        setShowMenuLateral(false);
        setIsOpenSinSesion(true);
    };

    const handleCloseSinSesion = () => {
        setIsOpenSinSesion(false);
    }

    return (
        <>
        <Navbar>
            <NavbarContent justify="end" className="navbar-content">
                <NavbarItem className="icon-button">
                    <MenuButton onClick={handleMenuButtonClick} />
                </NavbarItem>
                <NavbarItem className="icon-button navbar-brand">
                    <img src={logo} alt="Logo" className="navbar-logo" />
                </NavbarItem>
                <NavbarItem>
                    <LoginButton />
                </NavbarItem>
            </NavbarContent>
        </Navbar>
        

		{ showMenuLateral && 
            <MenuLateralContainer
                onClose={handleMenuButtonClick}
                onProfileClick={handleProfileClick} 
                handleBarSelect={handleBarSelect}
                openModalSinSesion={openModalSinSesion} />
        }    

        {showUserProfile && authenticated && 
            <UserProfile 
                onClose={handleCloseProfile} />
        }  

        {isOpenSinSesion && <SinSesion onClose={handleCloseSinSesion} />}
        
        </>
    )
}

export default NavBar