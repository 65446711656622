import React from 'react';
import imgUbicacion from '../../assets/icons/marcador.png'; 
import imgHorario from '../../assets/icons/reloj.png'; 
import imgRating from '../../assets/icons/estrella.png'; 
import IconTextSection from './IconTextSection';
import { Divider } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import MapUtils from '../../utils/MapUtils';

const InformacionSection = ({ bar, iteraciones }) => {

    const { t } = useTranslation();
    
    return (
        <>
            <IconTextSection
                icon={imgRating} 
                content={`${MapUtils.formato1Decimal(bar.rating)} (${iteraciones.numRatings} opiniones de usuarios)`} 
                translationKey="starsRating" 
            />
            <Divider className='bg-primary'/>
            <IconTextSection 
                icon={imgUbicacion} 
                content={bar.address} 
                translationKey="ubicacion"
            />
            <Divider className='bg-primary'/>
            <IconTextSection
                icon={imgHorario} 
                translationKey="horario"
                content={
                    <>
                        {bar.openingHours?.weekdayDescriptions?.map((horarioDia, index) => (
                            <div key={index} className="horario-dia">
                                <small>{horarioDia}</small>
                            </div>
                        ))}
                    </>
                } 
            />
        </>
    );
}

export default InformacionSection;
