class UserInfoBarDto {
    constructor(user, bar, temaPrincipal, subtemas) {
        this.user = user?._id;
        this.bar = bar._id;
        this.temaPrincipal = temaPrincipal.id;
        this.subtemas = subtemas;
    }
}

export default UserInfoBarDto;
