import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import "./SearchCard.styles.scss";
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/ApiService';
import { Card, CardBody, CardFooter, CardHeader, Radio, RadioGroup, Spinner } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import buscarIcon from '../../assets/icons/buscar.png';

const SearchCard = ({ onPlaceSelect, showCenterButton, handleCenterButtonClick, handleBarSelect, toggleSearchCard}) => {
	const [autocomplete, setAutocomplete] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [selected, setSelected] = useState("bares");
	const [searchTerm, setSearchTerm] = useState(''); // Estado para almacenar el término de búsqueda
  	const [searchResults, setSearchResults] = useState([]); // Estado para almacenar los resultados de la búsqueda
  	const [loading, setLoading] = useState(false); // Estado para indicar si se está realizando la búsqueda

	const { t } = useTranslation();

	useEffect(() => {
		if (autocomplete) {
			const listener = autocomplete.addListener('place_changed', () => {
				const place = autocomplete.getPlace();
				if (place.geometry) {
					onPlaceSelect(place);
					setSearchText(place.name);
				}
			});

			return () => {
				google.maps.event.removeListener(listener);
			};

		}
	}, [autocomplete, onPlaceSelect]);

	useEffect(() => {
		// Realiza la búsqueda cada vez que cambie el término de búsqueda
		if (searchTerm.trim() !== '') {
		  	searchBars();
		} else {
		  	setSearchResults([]); // Borra los resultados si el término de búsqueda está vacío
		}
	}, [searchTerm]);

	// Función para buscar bares
	const searchBars = async () => {
		try {
			setLoading(true);
			const response = await api.bars.buscadorBares(searchTerm);
			setSearchResults(response);
		} catch (error) {
		  	console.error('Error searching bars:', error);
		} finally {
		  	setLoading(false);
		}
	};

	const handleOptionSelect = (option) => {
		setSelected(option);
		// Limpiar el término de búsqueda al cambiar la opción
		setSearchTerm('');
		setSearchText('');
    	setSearchResults([]);
	}

	const handleInputChange = (e) => {
		if (selected === 'ubicacion') {
			setSearchText(e.target.value);
		} else {
			setSearchTerm(e.target.value);
		}
	};

	return (
			<div className="search-card-container">
				<Card className="search-card">
					<CardHeader className="search-card-header">
						<button onClick={toggleSearchCard}>
							<img src={ buscarIcon } alt="Search icon" className="w-7 h-7" />
						</button>
						<h5 className="search-card-title">
							{t('buscador.titulo')} 
						</h5>
					</CardHeader>
					<CardBody className="search-card-body">
						{selected === 'ubicacion' && (
							<Autocomplete onLoad={(auto) => setAutocomplete(auto)}>
								<SearchInput
									placeholder={t('buscador.nuevaUbicacionPlaceholder')}
									value={searchText}
									onChange={handleInputChange}
								/>
							</Autocomplete>
						)}
	
						{selected === 'bares' && (
							<>
								<SearchInput
									placeholder={t('buscador.buscarBarPlaceholder')}
									value={searchTerm}
									onChange={handleInputChange}
								/>
								{searchTerm.length > 0 && (
									<SearchResults items={searchResults} handleBarSelect={handleBarSelect}/>
								)}
							</>
						)}
						
						<RadioGroup
							value={selected}
							onValueChange={handleOptionSelect}
							className="search-radio-group"
						>
							<Radio value="ubicacion" className="search-radio">
								{t('buscador.tituloUbicacion')}
							</Radio>
							<Radio value="bares" className="search-radio">
								{t('buscador.tituloBuscador')}
							</Radio>
						</RadioGroup>
					</CardBody>
					
					{showCenterButton && (
						<CardFooter className="search-card-footer">
							<div className="map-button" onClick={handleCenterButtonClick}>
								<FontAwesomeIcon icon={faLocationCrosshairs} className="location-icon" />
								<span>{t('buscador.centrarUbicacion')}</span>
							</div>
						</CardFooter>
					)}
				</Card>
	
				{ loading && <Spinner />}
			</div>
		
	);
};

export default SearchCard;
