import React from "react";
import {Select, SelectItem, Avatar} from "@nextui-org/react";
import { useTranslation } from 'react-i18next';

const IdiomaSelect = ({idioma, handleIdioma}) => {

    const { t } = useTranslation();

    const handleSelectionChange = (e) => {
        handleIdioma(e.target.value);
    };

  return (
    <Select
        className="max-w-100"
        label={t('configuracion.idioma')}
        defaultSelectedKeys={[idioma]}
        onChange={handleSelectionChange}
    >
      <SelectItem
        key={"es"}
        startContent={<Avatar alt="Spain" className="w-6 h-6" src="https://flagcdn.com/es.svg" />}
      >
        {t('idiomas.es')}
      </SelectItem>
      <SelectItem
        key={"en"}
        startContent={<Avatar alt="English" className="w-6 h-6" src="https://flagcdn.com/gb.svg" />}
      >
        {t('idiomas.en')}
      </SelectItem>
    </Select>
  );
}

export default IdiomaSelect;
