import { Avatar } from '@nextui-org/react';
import React, { useEffect, useState } from 'react'
import classNames from 'classnames';

const IconosTemas = ({ tema, onTemaClick }) => {
  const { img, title } = tema;
  const [seleccionado, setSeleccionado] = useState(false);

  const handleClick = () => {
    setSeleccionado(!seleccionado); 
    onTemaClick(tema, !seleccionado);
  };

  const iconClass = classNames('icono-tema', {
    'seleccionado': seleccionado,
  });

  return (
     <div className={iconClass} onClick={handleClick}>
      <div className="icono-tema__avatar">
        <Avatar src={img} size="lg" className='avatar_img'/>
      </div>
      <b className="icono-tema__title">{title}</b>
    </div>
  );
}

export default IconosTemas;