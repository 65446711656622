function formatDateDDMMYYYY(dateString) {
    if (dateString) {
        const date = new Date(dateString);

        if (!isNaN(date.getTime())) {
            const day = date.getDate();
            const month = date.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por eso sumamos 1
            const year = date.getFullYear();
    
            return `${day}/${month}/${year}`;
        }
    }
    return "";
}

export default {
    formatDateDDMMYYYY
}