export const PREFERENCES = {
    musica:"musica",
    bebida: "bebida",
    ambiente: "ambiente",
    tipoLocal: "tipoLocal",
    actividad: "actividad",
}

export const FILTROS = {
    bares: "bares",
    cafeterias: "cafeterias",
    vinos: "vinos",
    masFiltros: "masFiltros"
}
    
export const LIST_BARES = {
    meGusta: "meGusta",
    guardados: "guardados",
    cercanos: "cercanos",
}