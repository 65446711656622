import { useParams } from "react-router-dom";
import { UserProvider } from "../contexts/UserContext";
import BarDetalles from "./BarDetalles/BarDetalles";


function BarInformacion() {
  const { id } = useParams();
  return (
      <BarDetalles barId={id}/> 
  );
}

export default BarInformacion;
