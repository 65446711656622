export const COLORES_ETIQUETAS = {
    restaurant: 'bg-red-500',
    food: 'bg-blue-300',
    point_of_interest: 'bg-purple-500',
    establishment: 'bg-teal-500',
    bar: 'bg-orange-500',
    night_club: 'bg-indigo-700',
    cafe: 'bg-yellow-800',
    store: 'bg-green-500',
    meal_takeaway: 'bg-yellow-500',
    meal_delivery: 'bg-orange-500',
    liquor_store: 'bg-purple-500',
    bakery: 'bg-green-800',
    supermarket: 'bg-yellow-300',
    parking: 'bg-gray-400',
    art_gallery: 'bg-pink-500',
    book_store: 'bg-blue-500',
    church: 'bg-yellow-700',
    stadium: 'bg-red-700',
    lodging: 'bg-green-600',
    gym: 'bg-blue-600',
    home_goods_store: 'bg-teal-700',
    electronics_store: 'bg-indigo-500',
    health: 'bg-purple-300',
    convenience_store: 'bg-orange-300',
    shopping_mall: 'bg-pink-700',
    university: 'bg-green-700',
    place_of_worship: 'bg-yellow-800',
    coffee_shop: 'bg-yellow-900',
    grocery_or_supermarket: 'bg-teal-300'
};