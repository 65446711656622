import React, { useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import { Avatar, AvatarIcon } from '@nextui-org/react';
import Login from '../ButtonLogin/Login';
import Register from '../ButtonLogin/Register';
import DropdownProfile from '../ButtonLogin/DropdownProfile';
import Logout from '../ButtonLogin/Logout';


const LoginButton = () => {
    const { authenticated } = useUser();
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showLogout, setShowLogout] = useState(false);

    const handleLogin = () => {
        setShowLogin(!showLogin);
    };

    const handleChangeForm = () => {
        setShowLogin(!showLogin);
        setShowRegister(!showRegister);
    };

    const handleClose = () => {
        setShowLogin(false);
        setShowRegister(false);
    };

    const handleLogout = () => {
        setShowLogout(true);
    }

    return (
        <div className="login-button-container">
            {!authenticated ? (
                <>
                <Avatar className="login-button" icon={<AvatarIcon />} size='md' onClick={handleLogin} />
                { showLogin && <Login
                    handleChangeForm={handleChangeForm}
                    handleClose={handleClose}
                />}
                
                { showRegister && <Register
                    handleChangeForm={handleChangeForm}
                    handleClose={handleClose}
                />}
                </>
                ) : (
                <>
                <div className="flex items-center login-button">
                    <DropdownProfile handleLogout={handleLogout}></DropdownProfile>
                </div>

                {showLogout && <Logout setShowLogout={setShowLogout} />}
                </>
            )}
        </div>
    );
};

export default LoginButton;
