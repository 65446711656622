import React from 'react';
import { Input } from '@nextui-org/react';
import searchIcon from '../../assets/icons/search-icon.png';

const SearchInput = ({ placeholder, onChange }) => {
  return (
    <Input
      size="sm"
      radius="full"
      type="text"
      placeholder={placeholder}
      aria-label="searchText"
      onChange={onChange}
      //startContent={<img src={searchIcon} alt="Search icon" className="search-icon" />}
    />
  );
};

export default SearchInput;
