import React, { useState, useEffect } from 'react';
import './NotificationIcon.scss';
import ReactDOM from 'react-dom';

const NotificationIcon = ({ imageUrl }) => {
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
        setShowIcon(false);
    }, 2000); 

    return () => clearTimeout(timeout);
  }, []);

  return showIcon ? ReactDOM.createPortal(
    <div className="notification-icon-container">
      <img className="notification-icon" src={imageUrl} alt="Notification Icon" />
    </div>,
    document.body
  ) : null;
};

export default NotificationIcon;
