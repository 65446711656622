import React from 'react';
import { Tabs, Tab } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import UserTabPerfil from './UserTabPerfil';
import UserTabPreferencias from './UserTabPreferencias';

const PreferencesTab = ({ selectedTab, setSelectedTab, editedUser, editing, handleSelectChange, handleChange, optionsPreferences }) => {
    const { t } = useTranslation();

    return (
        <Tabs 
            fullWidth
            size='md'
            aria-label='Tabs form'
            selectedKey={selectedTab}
            onSelectionChange={setSelectedTab}
        >
            <Tab key="perfil" title={t('userProfile.perfil')}>
                <UserTabPerfil
                    editing={editing} 
                    editedUser={editedUser} 
                    handleChange={handleChange}
                />
            </Tab>
            <Tab key="gustosypreferencias" title={t('userProfile.gyp.titulo')}>
                <UserTabPreferencias
                    editing={editing} 
                    editedUser={editedUser} 
                    handleSelectChange={handleSelectChange}
                    optionsPreferences={optionsPreferences}
                />
            </Tab>

        </Tabs>    
    );
};

export default PreferencesTab;
