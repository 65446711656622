import React from 'react';
import { Button } from '@nextui-org/react';

const MenuButton = ({ onClick }) => {
  return (
    <Button size="sm" variant="flat" onClick={onClick}>
      <span>&#9776;</span>
    </Button>
  );
};

export default MenuButton;
