import React, { useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Image} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import Login from "../ButtonLogin/Login";
import Register from "../ButtonLogin/Register";
import LogoCompletoGrande from '../../assets/icons/logoCompletoGrande.png';

export default function SinSesion({ onClose }) {
    const { t } = useTranslation();
	const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showSinSesion, setShowSinSesion] = useState(true);

	const handleRegister = () => {
		setShowRegister(true);   
		setShowSinSesion(false);
    };

	const handleChangeAuth = () => {
        setShowLogin(!showLogin);
        setShowRegister(!showRegister);
        setShowSinSesion(false);
    };

	return (
		<>
		{showSinSesion && 
			<Modal 
			isOpen={showSinSesion} 
			onOpenChange={onClose} 
			placement="center">
				<ModalContent className="rounded-lg shadow-lg">
				{(onClose) => (
					<>
					<ModalHeader className="flex flex-col items-center gap-1 text-center">
						<Image
							src={LogoCompletoGrande}
							alt="Logo Esmibar"
							width={200}
							height={50}
							objectFit="contain"
						/>
						<span className="font-semibold text-lg">{t('sinSesion.error')}</span>
					</ModalHeader>
					<ModalBody className="flex flex-col items-center">
						<h2 className="text-center font-bold text-primary">
						{t('sinSesion.titulo')}
						</h2>
						<p className="text-center text-lg mt-2">
						{t('sinSesion.texto1')}
						</p>
						<p className="text-center text-lg">
						{t('sinSesion.texto2')}
						</p>

					</ModalBody>
					<ModalFooter className="flex justify-between">
						<Button color="secondary" onPress={onClose}>
						{t('sinSesion.luego')}
						</Button>
						<Button color="primary" onPress={handleRegister}>
						{t('sinSesion.registrarme')}
						</Button>
					</ModalFooter>
					</>
				)}
				</ModalContent>
			</Modal>
		}

		{showLogin && <Login
            handleChangeForm={handleChangeAuth}
            handleClose={onClose}
        />}
          
        {showRegister && <Register
            handleChangeForm={handleChangeAuth}
            handleClose={onClose}
        />}
		</>
	);
}
