import { Avatar } from '@nextui-org/react'
import React from 'react'
import { useUser } from '../../contexts/UserContext';

function ImageProfile({alt, size, className}) {
    const { obtenerImagenPerfil, user } = useUser();

    return (
        <Avatar 
            isBordered 
            color="primary" 
            src={obtenerImagenPerfil(user)}
            alt={alt} 
            size={size} 
            className={className} 
        />
    )
}

export default ImageProfile