import React, { useCallback, useEffect, useState } from 'react'
import './UserProfile.styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subYears } from 'date-fns';
import DateUtils from '../../utils/DateUtils';
import { Avatar, AvatarIcon, Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tab, Tabs } from '@nextui-org/react';
import apiTags from '../../services/ApiTagService';
import { COUNTRIES, GENDER, PREFERENCES } from '../../constants';
import { useTranslation } from 'react-i18next';
import UserInformation from './UserInformation';
import PreferencesTab from './PreferencesTab';
import usePreferenceOptions from './usePreferenceOptions';
import UserPictureDto from '../../dto/userPictureDto';
import api from '../../services/ApiService';
import { useUser } from '../../contexts/UserContext';

const UserProfile = ({ onClose }) => {
    const { user, guardarUser, authenticated } = useUser();
    const [editing, setEditing] = useState(false);  
    const [editedUser, setEditedUser] = useState({ ...user });
    const [selected, setSelected] = useState("perfil");
    const [selectedImage, setSelectedImage] = useState(null);
    const { t } = useTranslation();

    const {
        optionsPreferenceMusic,
        optionsDrinkPreferences,
        optionsAmbiancePreference,
        optionsFavoriteBarTypes,
        optionsFavoriteActivities,
    } = usePreferenceOptions();

    const handleEdit = () => {
        setEditing(true);
    };
    
    const handleSaveProfile = async (editedUser) => {
        const response = await api.users.updateUser(editedUser);
        if (response.status == 200) {
            guardarUser(response.data);
        }
    }

    const handleSave = async () => {
        if (authenticated){
            await handleSaveProfile(editedUser);
            setEditing(false);
            if (selectedImage) {
                const userPictureDto = new UserPictureDto(user, selectedImage);
                const response = await api.users.pictureUpload(userPictureDto);
                if (response.status == 200) {
                    guardarUser(response.data);
                }

                setSelectedImage(null); 
            }
        }    
    };
    
    const handleCancel = () => {
        setEditing(false);
        setEditedUser({ ...user });
        setSelectedImage(null);
    };

    const handleClose = () => {
        handleCancel();
        onClose();
    }
    
    const handleChange = (fieldName, value) => {
        const [field, subfield] = fieldName.split(".");

        if (fieldName === "profile.dateOfBirth") {
            value = new Date(value);
        }

        setEditedUser((prevUser) => ({
            ...prevUser,
            [field]: {
                ...prevUser[field],
                [subfield]: value,
            },
        }));
    };

    const optionsPreferences = {
        tipoLocal: {
            label: t('userProfile.gyp.bares'),
            placeholder: t('userProfile.gyp.baresPlaceholder'),
            items: optionsFavoriteBarTypes
        },
        musica: {
            label: t('userProfile.gyp.musica'),
            placeholder: t('userProfile.gyp.musicaPlaceholder'),
            items: optionsPreferenceMusic
        },
        bebida: {
            label: t('userProfile.gyp.bebida'),
            placeholder: t('userProfile.gyp.bedidaPlaceholder'),
            items: optionsDrinkPreferences
        },
        ambiente: {
            label: t('userProfile.gyp.ambiente'),
            placeholder: t('userProfile.gyp.ambientePlaceholder'),
            items: optionsAmbiancePreference
        },
        actividad: {
            label: t('userProfile.gyp.actividades'),
            placeholder: t('userProfile.gyp.actividadesPlaceholder'),
            items: optionsFavoriteActivities
        }
    };
    
    const handleSelectChange = (selectedOptions) => {
        const updatedPreferences = { ...user.tastesAndPreferences, ...selectedOptions };
        // Actualiza el estado del usuario de manera inmutable
        setEditedUser((prevUser) => ({
            ...prevUser,
            tastesAndPreferences: updatedPreferences,
        }));
    };

    const handleImageSelect = (image) => {
        setSelectedImage(image);
    };

    const minDate = subYears(new Date(), 100); // Ajusta la cantidad de años que deseas hacia abajo

    return (
        <Modal isOpen={true} onOpenChange={handleClose} scrollBehavior='inside' >
            <ModalContent className="d-flex flex-column max-h-[99%]">
                {(onClose) => (
                <>
                    <ModalHeader className="flex flex-col gap-1">
                        <span className="title-text">{t('userProfile.titulo')}</span>
                    </ModalHeader>
                    <ModalBody className='pb-0'>
                        <UserInformation 
                            editing={editing} 
                            onImageSelect={handleImageSelect}
                            selectedImage={selectedImage}
                        />

                        <PreferencesTab 
                            selectedTab={selected} 
                            setSelectedTab={setSelected} 
                            editedUser={editedUser} 
                            editing={editing} 
                            handleSelectChange={handleSelectChange}
                            handleChange={handleChange}
                            optionsPreferences={optionsPreferences}
                        />
      
                    </ModalBody>
                    <ModalFooter className='pt-0'>
                        {editing ? 
                            <>
                            <Button onClick={handleCancel} >{t('cancelar')}</Button>
                            <Button color="primary" onClick={handleSave}>{t('guardar')}</Button>
                            </> 
                            : 
                            <Button color="primary" onClick={handleEdit}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        }
                    </ModalFooter>
                </>
                )}
            </ModalContent>
        </Modal>
  );
};

export default UserProfile