import { useCallback, useEffect, useRef, useState } from 'react';
import api from '../services/ApiService';
import { useUser } from '../contexts/UserContext';
import UserFiltrosDto from '../dto/userFiltrosDto';
import { useMap } from '../contexts/MapContext';
import debounce from 'lodash/debounce';

const useLoadBars = (userLocation, zoomLevel, userPreferences) => {
  const [error, setError] = useState(null);
  const { filtros } = useUser();
  const { baresTodos, setBaresTodos, setEstaCargandoBares } = useMap();

  const abortControllerRef = useRef(null);

  const mergeBars = useCallback((currentBars, newBars) => {
    const baresUnificados = [...currentBars];
    newBars.forEach(newBar => {
      if (!currentBars.some(bar => bar._id === newBar._id)) {
        baresUnificados.push(newBar);
      }
    });
    return baresUnificados;
  }, []);

  const loadBars = useCallback(
    debounce(async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      try {
        setEstaCargandoBares(true);
        console.log("loadBars -> SE LANZA");

        const userFiltrosDto = new UserFiltrosDto(userLocation, zoomLevel, userPreferences, filtros);
        const baresCercanos = await api.bars.getBarsNearbyAdd(userFiltrosDto, { signal });

        
        const baresTodosMasNuevos = mergeBars(baresTodos, baresCercanos);
        setBaresTodos(baresTodosMasNuevos);

      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(err);
        }
      } finally {
        setEstaCargandoBares(false);
      }
    }, 300),
    [userLocation, zoomLevel, userPreferences, filtros, mergeBars]
  )

  useEffect(() => {    
    if (userLocation && zoomLevel) {
      loadBars();
    }
  }, [userLocation, zoomLevel, userPreferences, filtros]);

  return { error };
};

export default useLoadBars;
