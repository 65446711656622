import { useTemasNotificaciones } from '../contexts/TemasNotificacionesContext';

const obtenerListadoTemasPrincipales = () => {
	const { temasConSubtemasVisibles } = useTemasNotificaciones();

	return temasConSubtemasVisibles;
}

const obtenerListadoTemas = (temaPrincipal) => {
    const { temasConSubtemasVisibles } = useTemasNotificaciones();

    const tema = temasConSubtemasVisibles.find(tema => tema.id === temaPrincipal.id);
    if (!tema) {
        return [];
    }

    return tema.subtemas;
};

export {
    obtenerListadoTemasPrincipales,
	obtenerListadoTemas
}