import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionItem, Avatar, Chip, Select, SelectItem } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import { useTemasNotificaciones } from '../../contexts/TemasNotificacionesContext';
import AccordionTitle from '../GustosYPreferencias/AccordionTitle';
import AccordionGustosYPreferencias from '../GustosYPreferencias/AccordionGustosYPreferencias';
import { useUser } from '../../contexts/UserContext';

const UserTabPreferencias = ({ editing, editedUser, handleSelectChange, optionsPreferences }) => {
    
    const { t } = useTranslation();
    const { temasConTodosSubtemas } = useTemasNotificaciones();
    const [selectedOptions, setSelectedOptions] = useState(null);
    
    useEffect(() => {
        // Inicializar el estado de las opciones seleccionadas con las preferencias del usuario
        if (temasConTodosSubtemas && editedUser) {
            const initialState = temasConTodosSubtemas.reduce((acc, tema) => ({
                ...acc,
                [tema.id]: editedUser?.tastesAndPreferences?.[tema.id] || [],
            }), {});
            setSelectedOptions(initialState);
        }
    }, [temasConTodosSubtemas, editedUser]);

    const handleSelectedOptionsChange = useCallback((updatedOptions) => {
        // Actualiza las opciones seleccionadas en el estado
        setSelectedOptions(updatedOptions);
        if (editing){
            handleSelectChange(updatedOptions);
        }
    });

    return (
        <div className="user-preference-form flex flex-col gap-2">
            {temasConTodosSubtemas && selectedOptions && (
                <AccordionGustosYPreferencias 
                    temasConTodosSubtemas={temasConTodosSubtemas} 
                    onSelectedOptionsChange={handleSelectedOptionsChange}
                    initialSelectedOptions={selectedOptions}
                />
            )}
        </div>
    );
};

export default UserTabPreferencias;
