import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const tags = "/tags";

const apiTags = {

    getTagsByStyle: async (style) => {
        try {
            const response = await axios.get(`${API_URL}${tags}/getTagsByStyle/${style}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    
    getAllListTemasPrincipales: async () => {
        try {
            const response = await axios.get(`${API_URL}${tags}/getAllListTemasPrincipales`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    
    getListTemasPrincipalesVisibles: async () => {
        try {
            const response = await axios.get(`${API_URL}${tags}/getListTemasPrincipalesVisibles`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, 
    
    getAllListSubtemasByTema: async (temaPrincipal) => {
        try {
            const response = await axios.get(`${API_URL}${tags}/getAllListSubtemasByTema/${temaPrincipal}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getListSubtemasVisiblesByTema: async (temaPrincipal) => {
        try {
            const response = await axios.get(`${API_URL}${tags}/getListSubtemasVisiblesByTema/${temaPrincipal}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getFiltrosRapidos: async () => {
        try {
            const response = await axios.get(`${API_URL}${tags}/getFiltrosRapidos`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    
}

export default apiTags;