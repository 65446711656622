import { Chip, Image } from '@nextui-org/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const IconTextSection = ({ icon, content, data, translationKey, miOpinion, abrirNotificaciones }) => {
    const { t } = useTranslation();
    return (
        <div className="flex gap-3">
            <Image src={icon} alt={translationKey} height={28} width={28} radius="sm" className="category-chip" />
            <div className="bar-detalles-types">
                <h6 className="category-chip">{t(`notificaciones.subtemas.${translationKey}.titulo`)}</h6>
                {data ? (
                    Object.entries(data).map(([tipo, value], idx) => (
                        <Chip key={idx} color="primary" variant="dot" className="category-chip">
                            {`${t(`tags.${translationKey}.${tipo}`)} (${value})`}
                        </Chip>
                    ))
                ) : content ? (
                    <p className="category-chip">{content}</p>
                ) : miOpinion ? (
                    <p className="category-chip" onClick={abrirNotificaciones}>{t('notificaciones.subtemas.miOpinionNoResults')}</p>
                ) : (
                    <p className="category-chip" onClick={abrirNotificaciones}>{t('notificaciones.subtemas.noResults')}</p>
                )
                    
                
                }
            </div>
        </div>
    )

};

export default IconTextSection;
