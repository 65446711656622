import React from 'react';
import BarMarker from '../Markers/BarMarker';
import UserMarker from '../Markers/UserMarker';
import BarInfoPopUp from '../BarInfoPopUp/BarInfoPopUp';
import BotonNotificarBar from '../BotonNotificarBar/BotonNotificarBar';

export const UserLocationMarker = ({ userLocation }) => (
    <UserMarker userLocation={userLocation} />
);

export const BarsMarkers = ({ bars, handleBarSelect, isTop }) => (
    <>
        {bars.map((bar) => (
            <BarMarker key={bar._id} bar={bar} onClick={handleBarSelect} isTop={isTop} />
        ))}
    </>
);

export const SelectedBarInfoWindow = ({ selectedBar, setSelectedBar }) => (
    <>
        <BarInfoPopUp />
        <BotonNotificarBar selectedBar={selectedBar} />
    </>
    
);
