import React, { createContext, useState, useContext, useEffect } from 'react';

// Creamos el contexto
const MapContext = createContext();

// Hook personalizado para facilitar el uso del contexto
export const useMap = () => useContext(MapContext);

// Proveedor del contexto
export const MapProvider = ({ children }) => {
  const [baresTodos, setBaresTodos] = useState([]);
  const [baresVisibles, setBaresVisibles] = useState([]);
  const [topBars, setTopBars] = useState([]);
  const [restBars, setRestBars] = useState([]);
  const [estaCargandoBares, setEstaCargandoBares] = useState(false);
  
  // Estado del mapa
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    // Aquí podrías hacer una llamada a la API para obtener todos los bares
    // y establecer el estado inicial cuando el componente se monta
    const fetchBares = async () => {
      setEstaCargandoBares(true);
      try {
        const response = await fetch('/api/bares'); // Cambia esta URL a tu endpoint
        const data = await response.json();
        
        setBaresTodos(data);
        setBaresVisibles(data); // Inicialmente mostramos todos los bares en el mapa
        // Aquí podrías filtrar para determinar topBars y restBars
        setTopBars(data.filter(bar => bar.rating > 4)); // Ejemplo de filtro
        setRestBars(data.filter(bar => bar.rating <= 4));
      } catch (error) {
        console.error('Error fetching bares:', error);
      } finally {
        setEstaCargandoBares(false);
      }
    };

    //fetchBares();
  }, []);

  const updateVisibleBars = (bars) => {
    const numBares = config.numBares || 10;
    if (map && userLocation) {
      const bounds = map.getBounds();
      const baresVisibles = bars.filter((bar) => bounds?.contains(bar.location));
      let newTopBars, newRestBars;

      if (filters?.length > 0) {
        const baresFiltrados = baresVisibles.filter(bar => bar.types.slice(0, 3).some(type => filters.includes(type)));
        newTopBars = baresFiltrados.slice(0, numBares);
        newRestBars = baresFiltrados.slice(numBares);
        setRestBars(prevRestBars => {
            // Filtrar los bares anteriores que coincidan con los filtros
            const filteredPrevRestBars = prevRestBars.filter(bar => bar.types.some(type => filters.includes(type)));
            
            // Crear un Set con los IDs de los bares filtrados actuales
            const restBarIds = new Set(filteredPrevRestBars.map(bar => bar._id));
            
            // Añadir los nuevos bares filtrados si no están ya en el Set
            newRestBars.forEach(bar => {
                if (!restBarIds.has(bar._id)) {
                    restBarIds.add(bar._id);
                    filteredPrevRestBars.push(bar);
                }
            });

            return filteredPrevRestBars;
        });
      } else {
        newTopBars = baresVisibles.slice(0, numBares);
        newRestBars = baresVisibles.slice(numBares);
        
        setRestBars(prevRestBars => {
            const restBarIds = new Set(prevRestBars.map(bar => bar._id));
            newRestBars.forEach(bar => {
                if (!restBarIds.has(bar._id)) {
                    restBarIds.add(bar._id);
                    prevRestBars.push(bar);
                }
            });
            return prevRestBars;
        });
      }
      
      setTopBars(newTopBars);

    }
  };

  // Proporcionamos los valores y métodos necesarios a los componentes hijos
  return (
    <MapContext.Provider
      value={{
        baresTodos,
        setBaresTodos,
        baresVisibles,
        setBaresVisibles,
        topBars,
        setTopBars,
        restBars,
        setRestBars,
        estaCargandoBares,
        setEstaCargandoBares,
        bounds,
        setBounds
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
