import React from 'react';

const TermsOfService = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Condiciones del Servicio</h1>
      <p><strong>Última actualización:</strong> 11/08/2024</p>

      <section>
        <h2>1. Introducción</h2>
        <p>
          Bienvenido a <strong>Esmibar</strong>. Estos Términos y Condiciones del Servicio ("Términos") rigen el acceso y uso de nuestra plataforma, 
          incluyendo cualquier contenido, funcionalidad y servicios ofrecidos en o a través de <a href="https://www.esmibar.com">www.esmibar.com</a> (el "Sitio Web").
        </p>
        <p>
          Al acceder o utilizar el Sitio Web, aceptas y acuerdas cumplir con estos Términos. Si no estás de acuerdo con estos Términos, no debes acceder ni utilizar el Sitio Web.
        </p>
      </section>

      <section>
        <h2>2. Descripción del Servicio</h2>
        <p>
          <strong>Esmibar</strong> proporciona [describe brevemente los servicios o productos que ofreces, por ejemplo: "una plataforma en línea para la gestión de bares y restaurantes, 
          permitiendo a los usuarios acceder a herramientas de administración, reservas, y análisis de negocio"].
        </p>
      </section>

      <section>
        <h2>3. Uso del Sitio Web</h2>

        <h3>3.1 Elegibilidad</h3>
        <p>
          Para utilizar nuestro Sitio Web, debes ser mayor de 18 años y tener la capacidad legal para celebrar un contrato vinculante. 
          Al utilizar nuestro servicio, declaras que cumples con estos requisitos.
        </p>

        <h3>3.2 Registro de Cuenta</h3>
        <p>
          Para acceder a ciertas funcionalidades, es posible que debas crear una cuenta. Aceptas proporcionar información veraz, precisa, actualizada y completa durante el proceso de registro 
          y mantener actualizada dicha información. Eres responsable de mantener la confidencialidad de tu cuenta y contraseña y de restringir el acceso a tu cuenta.
        </p>

        <h3>3.3 Conducta del Usuario</h3>
        <p>
          Al utilizar nuestro Sitio Web, te comprometes a:
        </p>
        <ul>
          <li>No utilizar el Sitio Web para ningún propósito ilegal o prohibido por estos Términos.</li>
          <li>No interferir con la seguridad del Sitio Web ni intentar obtener acceso no autorizado al Sitio Web, a sus servidores, o a datos.</li>
          <li>No utilizar el Sitio Web para transmitir cualquier material que sea difamatorio, ofensivo, o que infrinja los derechos de otros.</li>
        </ul>
      </section>

      <section>
        <h2>4. Propiedad Intelectual</h2>
        <p>
          Todos los contenidos, características y funcionalidades del Sitio Web, incluyendo, pero no limitado a, información, software, texto, pantallas, imágenes, vídeo y audio, 
          y su diseño, selección y disposición, son propiedad de <strong>Esmibar</strong>, sus licenciantes, o de otros proveedores de dicho material y están protegidos por leyes de derechos de autor, 
          marcas registradas, patentes, secretos comerciales y otros derechos de propiedad intelectual.
        </p>
        <p>
          No puedes copiar, modificar, crear trabajos derivados, exhibir públicamente, realizar, republicar, descargar, almacenar o transmitir ninguno de los materiales en nuestro Sitio Web, 
          excepto como se permite expresamente en estos Términos.
        </p>
      </section>

      <section>
        <h2>5. Limitación de Responsabilidad</h2>
        <p>
          <strong>Esmibar</strong> no será responsable de ningún daño directo, indirecto, incidental, especial, consecuente o punitivo, incluyendo pero no limitado a, pérdida de beneficios, datos, uso, fondo de comercio, 
          o cualquier otra pérdida intangible resultante de:
        </p>
        <ul>
          <li>Tu acceso o uso o la imposibilidad de acceder o utilizar el Sitio Web.</li>
          <li>Cualquier conducta o contenido de cualquier tercero en el Sitio Web.</li>
          <li>Cualquier contenido obtenido del Sitio Web.</li>
          <li>Acceso, uso, o alteración no autorizados de tus transmisiones o contenido.</li>
        </ul>
      </section>

      <section>
        <h2>6. Indemnización</h2>
        <p>
          Aceptas defender, indemnizar y mantener indemne a <strong>Esmibar</strong>, sus afiliados, licenciantes y proveedores de servicios, y a sus respectivos funcionarios, directores, empleados, contratistas, agentes, 
          licenciantes, proveedores, sucesores y cesionarios, de y contra cualquier reclamo, responsabilidad, daños, sentencias, premios, pérdidas, costos, gastos u honorarios 
          (incluidos honorarios razonables de abogados) que surjan o se relacionen con tu violación de estos Términos o tu uso del Sitio Web, incluyendo, pero no limitado a, 
          cualquier uso del contenido, servicios y productos distintos de los expresamente autorizados en estos Términos o tu uso de cualquier información obtenida del Sitio Web.
        </p>
      </section>

      <section>
        <h2>7. Modificaciones del Servicio y Términos</h2>
        <p>
          <strong>Esmibar</strong> se reserva el derecho a modificar o interrumpir, temporal o permanentemente, el Servicio (o cualquier parte del mismo) con o sin previo aviso. 
          También podemos revisar estos Términos en cualquier momento actualizándolos en esta página. Tu uso continuado del Sitio Web tras la publicación de los cambios constituye tu aceptación de dichos cambios.
        </p>
      </section>

      <section>
        <h2>8. Ley Aplicable</h2>
        <p>
          Estos Términos se regirán e interpretarán de acuerdo con las leyes de [tu país o estado]. 
          Cualquier disputa que surja de estos Términos o en relación con ellos se resolverá exclusivamente en los tribunales competentes de [tu ciudad o región].
        </p>
      </section>

      <section>
        <h2>9. Contacto</h2>
        <p>
          Si tienes alguna pregunta o comentario sobre estos Términos o sobre cualquier aspecto de nuestro Sitio Web, por favor contáctanos en:
        </p>
        <p>
          <strong>Esmibar</strong><br />
          Correo electrónico: <a href="mailto:luissnxz@gmail.com">luissnxz@gmail.com</a>
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
