import React from 'react';
import './ButtonShowBarList.styles.css';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

const ButtonShowBarList = ({ onClick }) => {
    const { t } = useTranslation();
    return (
      <Button radius="full" color="primary" variant="shadow" className='showBarsList' onClick={onClick}>
        {t('listadoBares.mostrarListado')}
      </Button>
    );
};

export default ButtonShowBarList;