import { Button, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem } from '@nextui-org/react';
import React, { useState } from 'react';
import LogoCompletoGrande from '../../assets/icons/logoCompletoGrande.png';


const CiudadSelector = ({ isVisible, onClose, onSelectCity, onSelectReubicar }) => {
    
    const [ciudad, setCiudad] = useState(null);
    const cities = [ 
        {key: "madrid", label: "Madrid", coords:{latitude: 40.41685, longitude: -3.703265}},
        /* {key: "pamplona", label: "Pamplona", coords:{latitude: 42.81687, longitude: -1.64323}}, */
        {key: "azagra", label: "Azagra", coords:{ latitude: 42.307782, longitude: -1.895156}},
    ]

    const handleExplorar = () => {
        console.log(ciudad);
        
        if (ciudad){
            console.log("entra", ciudad);
            
            onSelectCity(ciudad);
        }
    }

    return (
        <Modal isOpen={isVisible}>
            <ModalContent>
            {(onClose) => (
                <>
                <ModalHeader className="flex flex-col items-center gap-2">
                    <Image
                        src={LogoCompletoGrande}
                        alt="Logo Esmibar"
                        width={200}
                        height={50}
                        objectFit="contain"
                    />
                    <h2 className="text-lg font-semibold text-center">¡Ups! No podemos detectar tu ubicación.</h2>
                </ModalHeader>
                <ModalBody className="text-center">
                    <p className="mb-2">
                        Elige tu ciudad y déjate sorprender por los bares increíbles que hemos seleccionado solo para ti.🌟
                    </p>
                    <p className="mb-2">
                        Si lo prefieres, habilita los <u>permisos de ubicación</u> y déjanos llevarte a los mejores lugares cerca de ti. ¡Estamos aquí para ayudarte! 
                    </p>
                    <p>
                        Simplemente selecciona "Reubicar" después de otorgar los permisos y prepárate para una experiencia espectacular.🎉
                    </p>
                    <Select
                        label="¿Dónde estás?" 
                        className="w-full" 
                        onSelectionChange={(selectedKey) => {
                            console.log(selectedKey);
                            const selectedCity = cities.find(city => city.key === selectedKey.currentKey);
                            console.log(selectedCity);
                            setCiudad(selectedCity);
                        }}
                        >
                        {cities.map((city) => (
                            <SelectItem key={city.key}>
                                {city.label}
                            </SelectItem>
                        ))}
                    </Select>
                    
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" onPress={onSelectReubicar}>
                        Reubicar
                    </Button>
                    <Button color="primary" onPress={handleExplorar}>
                        ¡Explorar ahora!
                    </Button>
                </ModalFooter>
                </>
            )}
            </ModalContent>
        </Modal>
    );
};

export default CiudadSelector;
