import { Button, Image } from "@nextui-org/react";
import './ActionButtons.styles.scss';
import imgGYP from '../../assets/icons/pulgar-arriba-confianza.png';
import imgBuscador from '../../assets/icons/buscar-ubicacion.png';
import imgFiltroComun from '../../assets/icons/filtro_comun.png';


const ActionButtons = ({ onFilterClick, onSearchClick, onGypClick }) => {
    return (
        <div className="action-buttons-container">
            <Button isIconOnly color="primary" radius="full" className="action-button" onClick={onSearchClick} aria-label="Buscador"><Image src={imgBuscador} alt="Buscador"></Image></Button>
            <Button isIconOnly color="primary" radius="full" className="action-button" onClick={onGypClick} aria-label="GustosYPreferencias"><Image src={imgGYP} alt="GustosYPreferencias"></Image></Button>
            <Button isIconOnly color="primary" radius="full" className="action-button" onClick={onFilterClick} aria-label="Filtro"><Image src={imgFiltroComun} alt="Filtro"></Image></Button>
        </div>
    );
};

export default ActionButtons;
