import React, { useState } from 'react'
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Slider} from "@nextui-org/react";
import AuthUtils from '../../utils/AuthUtils';
import { useTranslation } from 'react-i18next';
import IdiomaSelect from './IdiomaSelect';
import UserConfigDto from '../../dto/userConfigDto';
import api from '../../services/ApiService';
import imgCheckOkey from '../../assets/icons/checkOkey.png';
import NotificationIcon from '../NotificationsPopUp/NotificationIcon';
import { useUser } from '../../contexts/UserContext';


const ConfigModal = ({onClose}) => {

    const { user, config, guardarUser, authenticated } = useUser();
    const [distBares, setDistBares] = useState(config.distBares);
    const [numBares, setNumBares] = useState(config.numBares);
    const [distZoom, setDistZoom] = useState(config.distZoom);
    const [idioma, setIdioma] = useState(config.idioma);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { t } = useTranslation();


    const handleDistBares = (value) => {
        setDistBares(value);
    }
    
    const handleNumBares = (value) => {
        setNumBares(value);
    }
    
    const handleDistZoom = (value) => {
        setDistZoom(value);
    }
    
    const handleIdioma = (value) => {
        setIdioma(value);
    }
    
    const handleSaveConfig = async () => {
        if (authenticated){
            const userConfigDto = new UserConfigDto(user, distBares, numBares, distZoom, idioma);
			const response = await api.users.guardarUserConfiguracion(userConfigDto);
			if (response.status == 200){
				const currentUser = response.data;
                guardarUser(currentUser);
                setShowConfirmation(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
			}
		}
    }

    return (
        <>
        <Modal 
            isOpen={true} 
            onOpenChange={onClose} >
            <ModalContent>
                {(onClose) => (
                <>
                    <ModalHeader className="text-lg font-bold text-center">{t('configuracion.titulo')}</ModalHeader>
                    <ModalBody>

                        <IdiomaSelect idioma={idioma} handleIdioma={handleIdioma} />
                    
                        <Slider
                            label={t('configuracion.distanciaBares')}
                            step={0.5} 
                            maxValue={10} 
                            minValue={0} 
                            defaultValue={distBares}
                            onChangeEnd={handleDistBares}
                            className="max-w-md"
                        />
                        
                        <Slider
                            label={t('configuracion.numBares')}
                            step={1} 
                            maxValue={20} 
                            minValue={1} 
                            defaultValue={numBares}
                            onChangeEnd={handleNumBares}
                            className="max-w-md"
                        />
                        
                        <Slider
                            label={t('configuracion.distanciaZoom')}
                            step={0.5} 
                            maxValue={20} 
                            minValue={14} 
                            defaultValue={distZoom}
                            onChangeEnd={handleDistZoom}
                            className="max-w-md"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary"  onPress={handleSaveConfig}>
                        {t('guardar')}
                        </Button>
                    </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
        
        {showConfirmation && (
			<NotificationIcon imageUrl={imgCheckOkey} />
		)}

        </>
    )
}

export default ConfigModal;