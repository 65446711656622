import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionItem, Chip, Avatar } from "@nextui-org/react";
import AccordionTitle from './AccordionTitle';
import { useTranslation } from 'react-i18next';

const AccordionGustosYPreferencias = ({ temasConTodosSubtemas, onSelectedOptionsChange, initialSelectedOptions }) => {
    const { t } = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions || {});
    const [expandedItems, setExpandedItems] = useState({});

    console.log(selectedOptions);
    

    useEffect(() => {
        onSelectedOptionsChange(selectedOptions); // Notifica los cambios al componente padre
    }, [selectedOptions, onSelectedOptionsChange]);

    const handleChipClick = useCallback((category, value) => {
        setSelectedOptions(prev => {
            const updatedOptions = {
                ...prev,
                [category]: prev[category].includes(value)
                    ? prev[category].filter(option => option !== value)
                    : [...prev[category], value]
            };
            return updatedOptions;
        });
    }, []);

    const handleAccordionChange = useCallback((id) => {
        setExpandedItems(prev => ({
            ...prev,
            [id]: !prev[id],
        }));
    }, []);

    return (
        <Accordion variant="splitted" isCompact>
            {temasConTodosSubtemas && temasConTodosSubtemas.length > 0 ? (
                temasConTodosSubtemas.map((tema) => (
                    <AccordionItem 
                        key={tema.id} 
                        aria-label={tema.title} 
                        title={
                            <AccordionTitle
                                title={tema.title} 
                                selectedOptions={selectedOptions[tema.id]} 
                                tema={tema} 
                                expanded={expandedItems[tema.id]}
                            />
                        }
                        startContent={<Avatar src={tema.img} size="sm" className='avatar_img'/>}
                        indicator={expandedItems[tema.id]}
                        onPress={() => handleAccordionChange(tema.id)}
                    >
                        <div className="subtemas-container flex gap-2 flex-wrap pb-2">
                            {tema.subtemas.map((subtema) => (
                                <Chip 
                                    key={subtema.id} 
                                    color="primary"
                                    variant={
                                        selectedOptions[tema.id]?.includes(subtema.id) ? 'solid' : 'bordered'
                                    }
                                    onClick={() => handleChipClick(tema.id, subtema.id)}
                                    className="hover:bg-primary-light transition-all"
                                >
                                    {subtema.title}
                                </Chip>
                            ))}
                        </div>
                    </AccordionItem>
                ))
            ) : (
                <AccordionItem key="default" aria-label="No Data" title="No hay datos">
                    <p>{t('noDataMessage', 'No hay temas disponibles en este momento.')}</p>
                </AccordionItem>
            )}
        </Accordion>
    );
};

export default AccordionGustosYPreferencias;
