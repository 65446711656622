import React from 'react'
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button} from "@nextui-org/react";
import AuthUtils from '../../utils/AuthUtils';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/UserContext';


const Logout = ({setShowLogout}) => {
    const { logout } = useUser();
    const { t } = useTranslation();

    const handleLogout = () => {
        logout();
        setShowLogout(false);
    };

    return (
        <Modal 
            isOpen={true} 
            onOpenChange={() => setShowLogout(false)} 
            placement="top">
            <ModalContent>
                {(onClose) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1">{t('auth.cerrarSesion')}</ModalHeader>
                    <ModalBody>{t('auth.cerrarSesionPregunta')}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" variant="ghost" onClick={handleLogout}>
                        {t('auth.cerrarSesion')}
                        </Button>
                        <Button color="primary"  onPress={onClose}>
                        {t('auth.continuar')}
                        </Button>
                    </ModalFooter>
                    </>
                )}
            </ModalContent>
            
        </Modal>
    )
}

export default Logout;