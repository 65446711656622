class UserFiltrosDto {
    constructor(userLocation,zoom, tastesAndPreferences, filtros) {
        this.userLocation = userLocation;
        this.zoom = zoom;
        this.tastesAndPreferences = tastesAndPreferences;
        this.filtros = filtros;
    }
}

export default UserFiltrosDto;
