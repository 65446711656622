import React, { useCallback, useEffect, useState } from 'react'
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Accordion, AccordionItem, Avatar, Chip} from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import api from '../../services/ApiService';
import imgCheckOkey from '../../assets/icons/checkOkey.png';
import NotificationIcon from '../NotificationsPopUp/NotificationIcon';
import { useUser } from '../../contexts/UserContext';
import { useTemasNotificaciones } from '../../contexts/TemasNotificacionesContext';
import AccordionTitle from './AccordionTitle';
import AccordionGustosYPreferencias from './AccordionGustosYPreferencias';


const GustosYPreferencias = ({ onClose }) => {

    const { user, guardarUser, authenticated } = useUser();
    const { t } = useTranslation();
    const { temasConTodosSubtemas } = useTemasNotificaciones();

    const [selectedOptions, setSelectedOptions] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        // Inicializar el estado de las opciones seleccionadas con las preferencias del usuario
        if (temasConTodosSubtemas && user) {
            const initialState = temasConTodosSubtemas.reduce((acc, tema) => ({
                ...acc,
                [tema.id]: user?.tastesAndPreferences?.[tema.id] || [],
            }), {});
            setSelectedOptions(initialState);
        }
    }, [temasConTodosSubtemas, user]);

    const handleSelectedOptionsChange = useCallback((updatedOptions) => {
        setSelectedOptions(updatedOptions);
    }, []);

    const handleSaveGyp = useCallback(async () => {
        if (authenticated) {
            const updatedPreferences = { ...user.tastesAndPreferences, ...selectedOptions };
            const response = await api.users.updateUser({ ...user, tastesAndPreferences: updatedPreferences });

            if (response.status === 200) {
                guardarUser(response.data);
                setShowConfirmation(true);
                setTimeout(() => setShowConfirmation(false), 2000); // Mostrar la confirmación temporalmente
            }
        }
    }, [authenticated, selectedOptions, user, guardarUser]);

    return (
        <>
        <Modal 
            isOpen={true} 
            onOpenChange={onClose}
            scrollBehavior="outside" >
            <ModalContent>
                {(onClose) => (
                <>
                    <ModalHeader className="text-lg font-bold text-center">
                        {t('userProfile.gyp.titulo')}
                    </ModalHeader>
                    <ModalBody>
                        {temasConTodosSubtemas && selectedOptions && (
                            <AccordionGustosYPreferencias 
                                temasConTodosSubtemas={temasConTodosSubtemas} 
                                onSelectedOptionsChange={handleSelectedOptionsChange}
                                initialSelectedOptions={selectedOptions}
                            />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary"  onPress={handleSaveGyp}>
                        {t('guardar')}
                        </Button>
                    </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
        
        {showConfirmation && (
			<NotificationIcon imageUrl={imgCheckOkey} />
		)}

        </>
    )
}

export default GustosYPreferencias;