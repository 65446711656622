import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import "./RatingStar.styles.css";
import UserRatingsDto from '../../dto/userRatingsDto';
import apiUserRatings from '../../services/ApiUserRatingService';
import AuthUtils from '../../utils/AuthUtils';
import UserBarDto from '../../dto/userBarDto';
import { useUser } from '../../contexts/UserContext';

const RatingStar = ({selectedBar}) => {
    const { user, authenticated } = useUser();
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [totalStars, setTotalStars] = useState(5);
    const [oldStarRating, setOldStarRating] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (authenticated) {
                    await obtenerRatingDeBarPorUsuarioYMostrarlo();
                }
            } catch (error) {
                console.error('Error al cargar datos:', error);
            }
        };
        
        if (!dataLoaded) {
            fetchData();
        }

    }, [dataLoaded]);

    useEffect(() => {
        if (rating !== 0 && rating !== oldStarRating) {
            guardarRatings();
        }
    }, [rating]);

    const obtenerRatingDeBarPorUsuarioYMostrarlo = async() => {
		const userBarDto = new UserBarDto(user, selectedBar);
		const starRating = await apiUserRatings.getRatingInBarByUser(userBarDto);
        setRating(starRating);
        setOldStarRating(starRating);
        setDataLoaded(true);
	}

    const handleMouseOver = (star) => {
        setHoverRating(star);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

    const handleClick = (star) => {
        setRating(star);
    };

    const guardarRatings = async () => {
        const userRating = new UserRatingsDto(user, selectedBar,null, rating, null);
        try {
            await apiUserRatings.guardarRatingsYComentarios(userRating);
        } catch (error) {
            console.error('Error al guardar la calificación:', error);
        }
    }

    return (
        <div className='rating-star'>
            {[...Array(totalStars)].map((_, index) => {
                const starValue = index + 1;
                return (
                    <FontAwesomeIcon
                        key={index}
                        icon={(starValue <= (hoverRating || rating)) ? solidStar : regularStar}
                        //className="star"
                        className={`star ${starValue <= (hoverRating || rating) ? 'selected' : ''}`}
                        onMouseOver={() => handleMouseOver(starValue)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleClick(starValue)}
                    />
                );
            })}
        </div>
    );
};

export default RatingStar;
