import { Avatar, AvatarIcon } from '@nextui-org/avatar'
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import UserProfile from '../UserProfile/UserProfile';
import api from '../../services/ApiService';
import ImageProfile from '../UserProfile/ImageProfile';
import { useUser } from '../../contexts/UserContext';
import { IDIOMAS } from '../../config/idiomas';

const DropdownProfile = ({handleLogout}) => {
  
  const { user } = useUser();
  const { i18n, t } = useTranslation();
  const [showUserProfile, setShowUserProfile] = useState(false);

  function onChangeLang(e) {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  }

  const handleProfileClick = () => {
    setShowUserProfile(true);
  };

  const handleCloseProfile = () => {
    setShowUserProfile(false);
  }


  return (
    <div className="flex items-center gap-4">
      <Dropdown placement="bottom-end">
        <DropdownTrigger>
          <div>
            <ImageProfile alt='Imagen de Perfil' size="sm" />
          </div>
        </DropdownTrigger>
        <DropdownMenu 
          color="primary" 
          variant="shadow"
          aria-label="Static Actions"
          classNames={{
                    list: "p-0",
                }}
          >
          <DropdownItem 
            key="profile"
            onClick={handleProfileClick}
            >{t('perfil.perfil')}</DropdownItem>
          <DropdownItem
            isReadOnly
            key="languaje"
            endContent={
              <select 
                defaultValue={i18n.language} 
                onChange={onChangeLang}
                className="z-10 outline-none w-16 py-0.5 rounded-md text-tiny group-data-[hover=true]:border-default-500 border-small border-default-300 dark:border-default-200 bg-transparent text-default-500"
                id="languaje"
                name="languaje"
              >
              {
                IDIOMAS.map(({code}) => (
                  <option 
                    key={code}
                    value={code}
                  >{t(`idiomas.${code}`)}</option>
                ))
              }
              </select>
            }
          >
            {t('perfil.idioma')}
          </DropdownItem>
          <DropdownItem key="delete"  color="danger" onClick={handleLogout}>{t('perfil.cerrarSesion')}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {showUserProfile && user && <UserProfile onClose={handleCloseProfile} />}
    </div>
  )
}

export default DropdownProfile