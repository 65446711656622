class UserConfigDto {
    constructor(user, distBares, numBares, distZoom, idioma) {
        this.user = user._id;
        this.distBares = distBares;
        this.numBares = numBares;
        this.distZoom = distZoom;
        this.idioma = idioma;
    }
}

export default UserConfigDto;
