export const COUNTRIES = [
    { code: 'ES'}, 
    { code: 'AF'}, 
    { code: 'AL'}, 
    { code: 'DZ'}, 
    { code: 'AS'}, 
    { code: 'AD'}, 
    { code: 'AO'}, 
    { code: 'AI'}, 
    { code: 'AQ'}, 
    { code: 'AG'}, 
    { code: 'AR'}, 
    { code: 'AM'}, 
    { code: 'AW'}, 
    { code: 'AU'}, 
    { code: 'AT'}, 
    { code: 'AZ'}, 
    { code: 'BS'}, 
    { code: 'BH'}, 
    { code: 'BD'}, 
    { code: 'BB'}, 
    { code: 'BY'}, 
    { code: 'BE'}, 
    { code: 'BZ'}, 
    { code: 'BJ'}, 
    { code: 'BM'}, 
    { code: 'BT'}, 
    { code: 'BO'}, 
    { code: 'BA'}, 
    { code: 'BW'}, 
    { code: 'BR'}, 
    { code: 'BN'}, 
    { code: 'BG'}, 
    { code: 'BF'}, 
    { code: 'BI'}, 
    { code: 'KH'}, 
    { code: 'CM'}, 
    { code: 'CA'}, 
    { code: 'CV'}, 
    { code: 'KY'}, 
    { code: 'CF'}, 
    { code: 'TD'}, 
    { code: 'CL'}, 
    { code: 'CN'}, 
    { code: 'CO'}, 
    { code: 'KM'}, 
    { code: 'CG'}, 
    { code: 'CD'}, 
    { code: 'CR'}, 
    { code: 'HR'}, 
    { code: 'CU'}, 
    { code: 'CY'}, 
    { code: 'CZ'}, 
    { code: 'DK'}, 
    { code: 'DJ'}, 
    { code: 'DM'}, 
    { code: 'DO'}, 
    { code: 'EC'}, 
    { code: 'EG'}, 
    { code: 'SV'}, 
    { code: 'GQ'}, 
    { code: 'ER'}, 
    { code: 'EE'}, 
    { code: 'ET'}, 
    { code: 'FJ'}, 
    { code: 'FI'}, 
    { code: 'FR'}, 
    { code: 'GA'}, 
    { code: 'GM'}, 
    { code: 'GE'}, 
    { code: 'DE'}, 
    { code: 'GH'}, 
    { code: 'GR'}, 
    { code: 'GD'}, 
    { code: 'GT'}, 
    { code: 'GN'}, 
    { code: 'GW'}, 
    { code: 'GY'}, 
    { code: 'HT'}, 
    { code: 'HN'}, 
    { code: 'HK'}, 
    { code: 'HU'}, 
    { code: 'IS'}, 
    { code: 'IN'}, 
    { code: 'ID'}, 
    { code: 'IR'}, 
    { code: 'IQ'}, 
    { code: 'IE'}, 
    { code: 'IL'}, 
    { code: 'IT'}, 
    { code: 'JM'}, 
    { code: 'JP'}, 
    { code: 'JO'}, 
    { code: 'KZ'}, 
    { code: 'KE'}, 
    { code: 'KI'}, 
    { code: 'KP'}, 
    { code: 'KR'}, 
    { code: 'KW'}, 
    { code: 'KG'}, 
    { code: 'LA'}, 
    { code: 'LV'}, 
    { code: 'LB'}, 
    { code: 'LS'}, 
    { code: 'LR'}, 
    { code: 'LY'}, 
    { code: 'LI'}, 
    { code: 'LT'}, 
    { code: 'LU'}, 
    { code: 'MO'}, 
    { code: 'MG'}, 
    { code: 'MW'}, 
    { code: 'MY'}, 
    { code: 'MV'}, 
    { code: 'ML'}, 
    { code: 'MT'}, 
    { code: 'MH'}, 
    { code: 'MR'}, 
    { code: 'MU'}, 
    { code: 'MX'}, 
    { code: 'FM'}, 
    { code: 'MD'}, 
    { code: 'MC'}, 
    { code: 'MN'}, 
    { code: 'ME'}, 
    { code: 'MA'}, 
    { code: 'MZ'}, 
    { code: 'MM'}, 
    { code: 'NA'}, 
    { code: 'NR'}, 
    { code: 'NP'}, 
    { code: 'NL'}, 
    { code: 'NZ'}, 
    { code: 'NI'}, 
    { code: 'NE'}, 
    { code: 'NG'}, 
    { code: 'NO'}, 
    { code: 'OM'}, 
    { code: 'PK'}, 
    { code: 'PW'}, 
    { code: 'PA'}, 
    { code: 'PG'}, 
    { code: 'PY'}, 
    { code: 'PE'}, 
    { code: 'PH'}, 
    { code: 'PL'}, 
    { code: 'PT'}, 
    { code: 'QA'}, 
    { code: 'RO'}, 
    { code: 'RU'}, 
    { code: 'RW'}, 
    { code: 'KN'}, 
    { code: 'LC'}, 
    { code: 'VC'}, 
    { code: 'WS'}, 
    { code: 'SM'}, 
    { code: 'ST'}, 
    { code: 'SA'}, 
    { code: 'SN'}, 
    { code: 'RS'}, 
    { code: 'SC'}, 
    { code: 'SL'}, 
    { code: 'SG'}, 
    { code: 'SK'}, 
    { code: 'SI'}, 
    { code: 'SB'}, 
    { code: 'ZA'}, 
    { code: 'ES'}, 
    { code: 'LK'}, 
    { code: 'SD'}, 
    { code: 'SR'}, 
    { code: 'SZ'}, 
    { code: 'SE'}, 
    { code: 'CH'}, 
    { code: 'SY'}, 
    { code: 'TW'}, 
    { code: 'TJ'}, 
    { code: 'TZ'}, 
    { code: 'TH'}, 
    { code: 'TL'}, 
    { code: 'TG'}, 
    { code: 'TO'}, 
    { code: 'TT'}, 
    { code: 'TN'}, 
    { code: 'TR'}, 
    { code: 'TM'}, 
    { code: 'UG'}, 
    { code: 'UA'}, 
    { code: 'AE'}, 
    { code: 'GB'}, 
    { code: 'US'}, 
    { code: 'UY'}, 
    { code: 'UZ'}, 
    { code: 'VU'}, 
    { code: 'VA'}, 
    { code: 'VE'}, 
    { code: 'VN'}, 
    { code: 'YE'}, 
    { code: 'ZM'}, 
    { code: 'ZW'}
];
