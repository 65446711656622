import { useEffect, useState } from "react";
import Map from "./Map/Map";
import SplashScreen from "./SplashScreen/SplashScreen";
import { useLoading } from "../hooks/useLoading";
import { useNavigate } from "react-router-dom";
import { getOnboardingStatus, setOnboardingStatus } from './OnboardingScreen/onboardingStatus';
import OnboardingScreen from "./OnboardingScreen/Onboarding";



function Home() {
    const [isMapLoading, setIsMapLoading] = useState(true);
    const [isOnboarding, setIsOnboarding] = useState(false);
    const splashDuration = 1500;
    const {startLoading} = useLoading();
    const navigate = useNavigate();


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsMapLoading(false);
            startLoading(true);
        }, splashDuration);
    
        return () => clearTimeout(timer);
    }, []);

    // Verifica si el onboarding ya se completó
    useEffect(() => {
       //const onboardingCompleted = getOnboardingStatus();
       const onboardingCompleted = false;
        if (!onboardingCompleted) {
            setIsOnboarding(true); // Muestra el onboarding
        } else {
            navigate('/map');  // Redirige a la ruta /map si ya completó el onboarding
        }
    }, [navigate]);

    const handleOnboardingComplete = () => {
        setOnboardingStatus(true); // Marca el onboarding como completado
        setIsOnboarding(false); // Oculta el onboarding y redirige
        navigate('/map'); // Redirige a /map
    };

    return (
        <>
            {isMapLoading ? (
                <SplashScreen />
            ) : isOnboarding ? (
                <OnboardingScreen onComplete={handleOnboardingComplete} />
            ) : (
                <Map />
            )}
        </>
    );
}

export default Home;
