import React from 'react';
import {
  Accordion,
  AccordionItem,
  Avatar,
  Button,
  Chip,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/UserContext';
import { useTemasNotificaciones } from '../../contexts/TemasNotificacionesContext';
import './FilterModal.styles.scss';

const FilterModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const { filtros, setFiltros, setAplicarTodosFiltros } = useUser();
    const { temasConTodosSubtemas } = useTemasNotificaciones();

    const handleChipClick = (filtro) => {        
        setFiltros((prevFilters) => {
            const exists = prevFilters?.some(item => item.name === filtro.name);
            return exists 
                ? prevFilters.filter(item => item.name !== filtro.name)
                : [...prevFilters, filtro];
        });
    };

    const resetFilters = () => {
        setFiltros([]);
        setAplicarTodosFiltros(true);
    };

    const getActiveSubtopicsCount = (tema) => {
        return filtros.filter((filtro) =>
            tema.subtemas.some((subtema) => subtema.id === filtro.name)
        ).length;
    };

    const aplicarFiltros = () => {
        setAplicarTodosFiltros(true);
        onClose();
    }

    return (
    <Modal isOpen={isOpen} onOpenChange={onClose} placement="top">
        <ModalContent>
            {() => (
            <>
                <ModalHeader className="flex flex-col gap-1 pb-0">
                <div className="flex justify-between items-center w-full">
                    <span>{t('filtro.filtros')}</span>
                </div>
                </ModalHeader>
                <ModalBody className="flex flex-col gap-4">
                    <div className="active-filters">
                        <div className="flex justify-between items-center w-full">
                            <span className="text-sm font-semibold text-gray-600">
                                {t('filtro.activos')}:
                            </span>
                            <div
                                className="flex items-center gap-1 cursor-pointer text-sm text-gray-500 hover:text-primary"
                                onClick={resetFilters}
                            >
                                <span>{t('filtro.restablecer')}</span>
                                <span className="text-lg">×</span>
                            </div>
                        </div>
                        <div className="flex gap-2 flex-wrap">
                        {filtros.length > 0 ? (
                            filtros.map((filtro, index) => (
                            <Chip
                                key={index}
                                color="primary"
                                variant="solid"
                                onClose={() => handleChipClick(filtro)}
                                className="hover:shadow transition-all"
                            >
                                {t(`tags.${filtro.style}.${filtro.name}`)}
                            </Chip>
                            ))
                        ) : (
                            <p className="text-sm text-gray-400">
                            {t('filtro.sinFiltros')}
                            </p>
                        )}
                        </div>
                    </div>

                    <Accordion variant="splitted" isCompact>
                        {temasConTodosSubtemas.length > 0 ? (
                            temasConTodosSubtemas.map((tema) => (
                                <AccordionItem
                                key={tema.id}
                                aria-label={tema.title}
                                title={
                                    <div className="flex items-center justify-between">
                                        <span className="font-semibold text-lg">{tema.title}</span>
                                        {getActiveSubtopicsCount(tema) > 0 && (
                                            <div className="flex items-center justify-center w-6 h-6 rounded-full bg-primary text-white text-sm">
                                                {getActiveSubtopicsCount(tema)}
                                            </div>
                                        )}
                                    </div>
                                }
                                startContent={<Avatar src={tema.img} size="sm" className="avatar_img" />}
                                >
                                    <div className="subtemas-container flex gap-2 flex-wrap pb-2">
                                        {tema.subtemas.map((subtema) => (
                                            <Chip
                                                key={subtema.id}
                                                color="primary"
                                                variant={filtros.some(filtro => filtro.name === subtema.id) ? 'solid' : 'bordered'}
                                                clickable
                                                onClick={() => handleChipClick({ name: subtema.id, style: tema.id })}
                                                className="hover:bg-primary-light transition-all"
                                            >
                                                {subtema.title}
                                            </Chip>
                                        ))}
                                    </div>
                                </AccordionItem>
                            ))
                            ) : (
                            <AccordionItem key="default" aria-label="No Data" title={t('noDataTitle', 'No hay datos')}>
                                <p>{t('noDataMessage', 'No hay temas disponibles en este momento.')}</p>
                            </AccordionItem>
                            )}
                    </Accordion>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="w-full text-base font-semibold bg-gradient-to-r from-primary to-primary-light hover:opacity-90"
                        onClick={aplicarFiltros}
                    >
                        {t('filtro.filtrar')}
                    </Button>
                </ModalFooter>
            </>
            )}
        </ModalContent>
    </Modal>
    );
};

export default FilterModal;
