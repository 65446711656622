// Onboarding.js
import React, { useState } from 'react';
import { Award, Beer, ChevronLeft, ChevronRight, MapPin, Star, User } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from '@nextui-org/react';
import logoCompleto from '../../assets/icons/marca/logoCompletoGrande.png';

const OnboardingScreen = ({onComplete}) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [direction, setDirection] = useState(1);
  const screens = [
    {
      id: 1,
      title: "¡Bienvenido a Esmibar!",
      text: "Descubre los mejores bares cerca de ti, personalizados a tus gustos.",
      image: "https://images.unsplash.com/photo-1538488881038-e252a119ace7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
      icon: <Beer className="h-16 w-16 text-yellow-400" />,
      cta: "Comenzar a Explorar",
    },
    {
      id: 2,
      title: "Bares que encajan contigo",
      text: "¿Te gustan los cócteles exóticos o un ambiente acogedor? ¡Déjanos conocerte para recomendarte lo mejor!",
      image: "https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
      icon: <User className="h-16 w-16 text-green-500" />,
      cta: "Personaliza tu Experiencia",
    },
    {
      id: 3,
      title: "Regístrate para una experiencia personalizada",
      text: "Regístrate y guarda tus preferencias para obtener recomendaciones a medida.",
      image: "https://images.unsplash.com/photo-1579389083395-4507e98b5e67?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80",
      icon: <User className="h-16 w-16 text-yellow-400" />,
      cta: "Regístrate Gratis",
    },
    {
      id: 4,
      title: "Encuentra bares cerca en tiempo real",
      text: "¡No pierdas tiempo buscando! Activa la ubicación para ver bares increíbles justo donde estás.",
      image: "https://images.unsplash.com/photo-1592861956120-e524fc739696?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
      icon: <MapPin className="h-16 w-16 text-green-500" />,
      cta: "Habilitar Ubicación",
    },
    {
      id: 5,
      title: "Comparte tu experiencia y gana",
      text: "Deja tu opinión, califica bares y desbloquea insignias exclusivas. ¡Haz que tu voz cuente!",
      image: "https://images.unsplash.com/photo-1577563908411-5077b6dc7624?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
      icon: <Star className="h-16 w-16 text-yellow-400" />,
      cta: "Gana Insignias Hoy",
    },
    {
      id: 6,
      title: "¡Comencemos!",
      text: "Es hora de descubrir los bares más increíbles que Esmibar ha encontrado para ti. ¡Vamos a ello!",
      image: "https://images.unsplash.com/photo-1575444758702-4a6b9222336e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
      icon: <Award className="h-16 w-16 text-green-500" />,
      cta: "Empieza Ahora",
    },
  ]

  const nextScreen = (id) => {
    if (id === 6){
      onComplete();
    }
    setDirection(1)
    setCurrentScreen((prev) => (prev + 1) % screens.length)
  }

  const prevScreen = () => {
    setDirection(-1)
    setCurrentScreen((prev) => (prev - 1 + screens.length) % screens.length)
  }

  const skipOnboarding = () => {
    onComplete();
    console.log("Onboarding saltado");
  }

  return (
    <div className="flex h-screen w-full items-center justify-center bg-gradient-to-br from-primary-dark to-primary-light">
      <div className="relative h-[650px] w-[350px] overflow-hidden rounded-3xl bg-white/30 shadow-xl backdrop-blur-md">

        <AnimatePresence initial={false} custom={direction}>
          {/* LOGO - Fijo en la parte superior */}
          <motion.div
            className="absolute inset-0 items-center justify-between p-12 pt-3 text-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <img 
              src={logoCompleto} 
              alt="Esmibar Logo" 
              className="w-full h-auto" // Mantiene el logo proporcional y lo ajusta al ancho.
            />
          </motion.div>

          <motion.div
            key={currentScreen}
            custom={direction}
            initial={{ opacity: 0, x: direction > 0 ? 300 : -300 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: direction > 0 ? -300 : 300 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="absolute inset-0 flex flex-col items-center justify-between p-6 pt-0 pb-12 text-center"
          >
            <div className="flex flex-col items-center space-y-4 mt-20">
              <div className="rounded-full bg-white/50 p-4 shadow-lg">
                {screens[currentScreen].icon}
              </div>
              <h2 className="text-2xl font-bold text-gray-800">{screens[currentScreen].title}</h2>
              <p className="text-gray-700">{screens[currentScreen].text}</p>
            </div>
            <div className="relative h-48 w-full overflow-hidden rounded-xl shadow-lg">
              <img
                alt={`Esmibar característica: ${screens[currentScreen].title}`}
                className="h-full w-full object-cover"
                src={screens[currentScreen].image}
              />
            </div>
            <div className="w-full space-y-4">
              <Button 
                className="w-full bg-gradient-to-r from-primary to-secondary-amarillo text-white hover:from-primary hover:to-secondary-amarillo transition-all duration-300 shadow-md"
                onClick={() => {nextScreen(screens[currentScreen].id)}}
              >
                {screens[currentScreen].cta}
              </Button>
            </div>
            
            {/* Indicadores de pantalla - Fijo en la parte inferior */}
            <div className="absolute bottom-6 flex justify-center space-x-2">
              {screens.map((_, index) => (
                <div
                  key={index}
                  className={`h-2 w-2 rounded-full transition-all duration-300 ${
                    currentScreen === index ? "bg-primary w-4" : "bg-gray-300"
                  }`}
                />
              ))}
            </div>
            
          </motion.div>
        </AnimatePresence>
        <button
          onClick={prevScreen}
          className="absolute left-2 bottom-2 rounded-full p-2 transition-all "
          aria-label="Pantalla anterior"
        >
          <ChevronLeft className="h-6 w-6 text-gray-800" />
        </button>
        <button
          onClick={skipOnboarding}
          className="absolute top-3 right-3 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors duration-300"
        >
          Saltar
        </button>
      </div>
    </div>
  );
};

export default OnboardingScreen;
