import './styles/main.css';
import './styles/custom.scss';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import AppRoutes from './Routes';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Analytics/> 
        <SpeedInsights />
      </header>
      <main>
        <AppRoutes/>
      </main>
    </div>
  );
}

export default App;
