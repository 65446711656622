import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import FiltersBars from './FiltersBars';
import masFiltrosIcon from '../../assets/icons/filtrar.png';
import imgFiltroComun from '../../assets/icons/filtro_comun.png';
import './FilterCard.styles.scss';

const FilterCard = ({ handleFiltrosRapidos, toggleFilterCard }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="filter-card-container">
            <div className=" d-flex justify-content-end">
                <Card className="filter-card">
                    <CardHeader className="filter-card-header">
                        <h5 className="filter-card-title">{t('filtro.titulo')}</h5>
                        <button className="text-xl text-default-500" onClick={toggleFilterCard}>
                            <img src={expanded ? imgFiltroComun : masFiltrosIcon} alt="Search icon" className="w-7 h-7" />
                        </button>
                    </CardHeader>
                    <CardBody className={`filter-card-body ${expanded ? 'expanded' : ''}`}>
                        <FiltersBars barsByFilter={handleFiltrosRapidos} />
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default FilterCard;
