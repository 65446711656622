import React from 'react';
import iconLocationBarTop from '../../assets/icons/bar.png';
import iconLocationCafeTop from '../../assets/icons/cafeteria.png';
import iconLocationWineTop from '../../assets/icons/cocktail.png';
import iconLocationBarRest from '../../assets/icons/circulo-verde.png';
import { MarkerF } from '@react-google-maps/api';


const setIcon = ({bar, isTop}) => {
  let icon = iconLocationBarRest;
  if (isTop) {
    for (const type of bar?.types) {
      switch (type) {
        case "bar":
          icon = iconLocationBarTop;
          break;
        case "cafe":
        case "bakery":
        case "restaurant":
        case "hotel":
        case "hostal":
          icon = iconLocationCafeTop;
          break;
        case "liquor_store":
          icon = iconLocationWineTop;
          break;
        default:
          icon = iconLocationBarTop;
          break;
      }
      //Salir del for con el primer tipo que encuentre.
      if (icon !== iconLocationBarRest) {
        break;
      }
    }
  }
  return icon;
}

const BarMarker = React.memo(({ bar, onClick, isTop }) => (
    <MarkerF
      key={bar._id}
      position={bar.location}
      icon={{
        url: setIcon({bar,isTop}),
        scaledSize: isTop ? new window.google.maps.Size(35, 35) : new window.google.maps.Size(7, 7),
      }}
      onClick={() => onClick(bar)}
      animation={isTop ? window.google.maps.Animation.DROP : null}
    />
  ), (prevProps, nextProps) => prevProps.bar._id === nextProps.bar._id);

export default BarMarker;