import React from 'react';
import iconLocation from '../../assets/icons/you-are-here.png';
import { MarkerF } from '@react-google-maps/api';


const UserMarker = React.memo(({ userLocation }) => (
    <MarkerF 
    position={userLocation} 
    icon={{
      url: iconLocation,
      scaledSize: new window.google.maps.Size(30, 30),
    }}
    animation={window.google.maps.Animation.DROP}
    />
));

export default UserMarker;