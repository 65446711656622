import { Chip } from '@nextui-org/react';
import React from 'react';
import { isOpenNow } from '../../utils/Utils';
import { useTranslation } from 'react-i18next';

const BarStatusChip = ({ hoursArray }) => {
    const { t } = useTranslation();
    return (
        <>
            {isOpenNow(hoursArray) != null ? (
                isOpenNow(hoursArray) ? 
                <Chip color="primary" className="ml-auto">
                    {t('detallesBares.abierto')}
                </Chip>
                : 
                <Chip color="danger" className="ml-auto">
                    {t('detallesBares.cerrado')}
                </Chip>
            ) : (
                <></>
            )}
        </>
    );
};

export default BarStatusChip;
