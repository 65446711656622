import React from 'react';
import imgGenteIcon from '../../assets/icons/genteIcon.png'; 
import imgAmbientIcon from '../../assets/icons/ambientIcon.png'; 
import imgPrecioIcon from '../../assets/icons/precioIcon.png'; 
import imgCaracteristicasIcon from '../../assets/icons/caracteristicasIcon.png'; 
import imgTipoLocalIcon from '../../assets/icons/tipoLocalIcon.png'; 
import { Divider } from '@nextui-org/react';
import IconTextSection from './IconTextSection';

const OpinionesSection = ({ iteracionesFiltradas, miOpinion, abrirNotificaciones }) => {
    const sections = [
        { key: 'tipoLocal', icon: imgTipoLocalIcon },
        { key: 'caracteristicas', icon: imgCaracteristicasIcon },
        { key: 'precio', icon: imgPrecioIcon },
        { key: 'gente', icon: imgGenteIcon },
        { key: 'ambiente', icon: imgAmbientIcon },
    ];

    return (
        <>
            {sections.map((section, idx) => (
                <React.Fragment key={section.key}>
                    <IconTextSection
                        icon={section.icon} 
                        data={iteracionesFiltradas[section.key]} 
                        translationKey={section.key} 
                        miOpinion={miOpinion}
                        abrirNotificaciones={abrirNotificaciones}
                    />
                    {idx < sections.length - 1 && <Divider className='bg-primary' />}
                </React.Fragment>
            ))}
        </>
    );
};

export default OpinionesSection;
